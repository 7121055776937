/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useLayoutEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from 'react-three-fiber'

export default function Model({ ...props }) {
  const { scene, nodes, materials } = useGLTF('All_Liver_Island.glb')
  // if (nodes) {
  //   setTimeout(() => {
  //     props.onCreated()
  //   }, 100)
  // }
  const group = useRef()
  const Factory_3 = useRef()

  // Factory_3.style.cursor = 'pointer'
  // const wieken = useRef()
  // useFrame(() => {
  //   console.log("Hey, I'm executing every frame!")
  // })

  return (
    <group ref={group} {...props} dispose={null}>
      <group
        name="Scene"
        userData={{ M3: { uv_sync_select: 1 }, glTF2ExportSettings: { export_extras: 1, use_selection: 1 } }}>
        <group name="Text014" userData={{ name: 'Text.014' }} />
        <group
          name="Camera"
          position={[65.16, 87.15, 74.67]}
          rotation={[0.93, 0.51, -0.58]}
          userData={{ name: 'Camera' }}
        />
        <mesh
          name="Ground_Liver_Island"
          geometry={nodes.Ground_Liver_Island.geometry}
          material={materials['Ground Liver Island']}
          position={[-4.95, -0.35, 1]}
          userData={{ name: 'Ground Liver Island' }}
        />
        <mesh
          name="Cube"
          geometry={nodes.Cube.geometry}
          material={nodes.Cube.material}
          position={[13.53, 0.45, 5.37]}
          scale={[0.55, 1, 0.55]}
          userData={{ name: 'Cube' }}
        />
        <mesh
          name="Cube001"
          geometry={nodes.Cube001.geometry}
          material={nodes.Cube001.material}
          position={[11.35, 0.76, 6.81]}
          scale={0.8}
          userData={{ name: 'Cube.001' }}
        />
        <mesh
          name="Cube002"
          geometry={nodes.Cube002.geometry}
          material={nodes.Cube002.material}
          position={[11.36, 1.02, 5.28]}
          userData={{ name: 'Cube.002' }}
        />
        <mesh
          name="Cube003"
          geometry={nodes.Cube003.geometry}
          material={nodes.Cube003.material}
          position={[11.35, 0.41, 7.33]}
          userData={{ name: 'Cube.003' }}
        />
        <mesh
          name="Cube005"
          geometry={nodes.Cube005.geometry}
          material={nodes.Cube005.material}
          position={[14.38, 1.27, 5.29]}
          userData={{ name: 'Cube.005' }}
        />
        <mesh
          name="Cube008"
          geometry={nodes.Cube008.geometry}
          material={nodes.Cube008.material}
          position={[11.36, 1.04, 5.33]}
          userData={{ name: 'Cube.008' }}
        />
        <mesh
          name="Cube010"
          geometry={nodes.Cube010.geometry}
          material={nodes.Cube010.material}
          position={[15.3, 1.57, 5.29]}
          userData={{ name: 'Cube.010' }}
        />
        <mesh
          name="Cube039"
          geometry={nodes.Cube039.geometry}
          material={nodes.Cube039.material}
          position={[15.01, 1.68, 5.29]}
          userData={{ name: 'Cube.039' }}
        />
        <mesh
          name="Cylinder003"
          geometry={nodes.Cylinder003.geometry}
          material={nodes.Cylinder003.material}
          position={[15.39, 1.63, 6.07]}
          userData={{ name: 'Cylinder.003' }}
        />
        <mesh
          name="Hospital_2"
          geometry={nodes.Hospital_2.geometry}
          material={nodes.Hospital_2.material}
          position={[8.41, 1.19, 5.25]}
          userData={{ name: 'Hospital 2' }}
        />
        <mesh
          name="Cube043"
          geometry={nodes.Cube043.geometry}
          material={nodes.Cube043.material}
          position={[8.66, 1.63, 5.35]}
          userData={{ name: 'Cube.043' }}
        />
        <mesh
          name="Cube011"
          geometry={nodes.Cube011.geometry}
          material={nodes.Cube011.material}
          position={[15.3, 1.14, 5.29]}
          userData={{ name: 'Cube.011' }}
        />
        <mesh
          name="Cube013"
          geometry={nodes.Cube013.geometry}
          material={nodes.Cube013.material}
          position={[15.3, 0.71, 5.29]}
          userData={{ name: 'Cube.013' }}
        />
        <mesh
          name="Cylinder001"
          geometry={nodes.Cylinder001.geometry}
          material={nodes.Cylinder001.material}
          position={[15.39, 1.63, 4.47]}
          userData={{ name: 'Cylinder.001' }}
        />
        <mesh
          name="Cylinder004"
          geometry={nodes.Cylinder004.geometry}
          material={nodes.Cylinder004.material}
          position={[15.39, 1.21, 6.07]}
          userData={{ name: 'Cylinder.004' }}
        />
        <mesh
          name="Cylinder005"
          geometry={nodes.Cylinder005.geometry}
          material={nodes.Cylinder005.material}
          position={[15.39, 1.21, 4.47]}
          userData={{ name: 'Cylinder.005' }}
        />
        <mesh
          name="Cylinder006"
          geometry={nodes.Cylinder006.geometry}
          material={nodes.Cylinder006.material}
          position={[15.39, 0.76, 6.07]}
          userData={{ name: 'Cylinder.006' }}
        />
        <mesh
          name="Cylinder007"
          geometry={nodes.Cylinder007.geometry}
          material={nodes.Cylinder007.material}
          position={[15.39, 0.76, 4.47]}
          userData={{ name: 'Cylinder.007' }}
        />
        <mesh
          name="Ventiladores_de_techo006"
          geometry={nodes.Ventiladores_de_techo006.geometry}
          material={nodes.Ventiladores_de_techo006.material}
          position={[10.81, 2.62, 5.26]}
          userData={{ name: 'Ventiladores de techo.006' }}
        />
        <mesh
          name="Ground_Hospital"
          geometry={nodes.Ground_Hospital.geometry}
          material={nodes.Ground_Hospital.material}
          position={[11.02, 0.05, 6.68]}
          userData={{ name: 'Ground Hospital' }}
        />
        <mesh
          name="Isla001"
          geometry={nodes.Isla001.geometry}
          material={nodes.Isla001.material}
          position={[10.14, 0.18, 9.13]}
          userData={{ name: 'Isla.001' }}
        />
        <mesh
          name="Isla002"
          geometry={nodes.Isla002.geometry}
          material={nodes.Isla002.material}
          position={[11.47, 0.18, 9.19]}
          userData={{ name: 'Isla.002' }}
        />
        <mesh
          name="Isla007"
          geometry={nodes.Isla007.geometry}
          material={nodes.Isla007.material}
          position={[10.9, 0.09, 9.27]}
          userData={{ name: 'Isla.007' }}
        />
        <mesh
          name="Isla008"
          geometry={nodes.Isla008.geometry}
          material={nodes.Isla008.material}
          position={[13.43, 0.1, 9.25]}
          userData={{ name: 'Isla.008' }}
        />
        <mesh
          name="Isla009"
          geometry={nodes.Isla009.geometry}
          material={nodes.Isla009.material}
          position={[8.48, 0.1, 7.93]}
          userData={{ name: 'Isla.009' }}
        />
        <mesh
          name="Poste005"
          geometry={nodes.Poste005.geometry}
          material={nodes.Poste005.material}
          position={[12.68, 1.26, 10]}
          userData={{ name: 'Poste.005' }}
        />
        <mesh
          name="Poste006"
          geometry={nodes.Poste006.geometry}
          material={nodes.Poste006.material}
          position={[9.01, 1.26, 8.82]}
          userData={{ name: 'Poste.006' }}
        />
        <mesh
          name="Poste007"
          geometry={nodes.Poste007.geometry}
          material={nodes.Poste007.material}
          position={[5.83, 1.26, 6.62]}
          userData={{ name: 'Poste.007' }}
        />
        <mesh
          name="Poste008"
          geometry={nodes.Poste008.geometry}
          material={nodes.Poste008.material}
          position={[14.18, 1.26, 3.11]}
          userData={{ name: 'Poste.008' }}
        />
        <mesh
          name="Poste009"
          geometry={nodes.Poste009.geometry}
          material={nodes.Poste009.material}
          position={[9.75, 1.26, 3.03]}
          userData={{ name: 'Poste.009' }}
        />
        <mesh
          name="Tree001"
          geometry={nodes.Tree001.geometry}
          material={nodes.Tree001.material}
          position={[8.26, 1.05, 7.98]}
          userData={{ name: 'Tree.001' }}
        />
        <mesh
          name="Tree002"
          geometry={nodes.Tree002.geometry}
          material={nodes.Tree002.material}
          position={[15.39, 1.1, 7.62]}
          userData={{ name: 'Tree.002' }}
        />
        <mesh
          name="Tree013"
          geometry={nodes.Tree013.geometry}
          material={nodes.Tree013.material}
          position={[13.59, 1.05, 9.26]}
          userData={{ name: 'Tree.013' }}
        />
        <mesh
          name="Cube177"
          geometry={nodes.Cube177.geometry}
          material={nodes.Cube177.material}
          position={[15.01, 1.24, 5.29]}
          userData={{ name: 'Cube.177' }}
        />
        <mesh
          name="Cube178"
          geometry={nodes.Cube178.geometry}
          material={nodes.Cube178.material}
          position={[15.01, 0.81, 5.29]}
          userData={{ name: 'Cube.178' }}
        />
        <group name="Plane" position={[11.43, 2.75, 5.22]} userData={{ name: 'Plane' }}>
          <mesh name="Plane003_1" geometry={nodes.Plane003_1.geometry} material={nodes.Plane003_1.material} />
          <mesh name="Plane003_2" geometry={nodes.Plane003_2.geometry} material={nodes.Plane003_2.material} />
        </group>
        <mesh
          name="Text"
          geometry={nodes.Text.geometry}
          material={nodes.Text.material}
          position={[11.37, 2.47, 6.26]}
          userData={{ name: 'Text' }}
        />
        <mesh
          name="Text004"
          geometry={nodes.Text004.geometry}
          material={nodes.Text004.material}
          position={[8.6, 2.23, 6.35]}
          userData={{ name: 'Text.004' }}
        />
        <mesh
          name="Wall"
          geometry={nodes.Wall.geometry}
          material={nodes.Wall.material}
          position={[-32.36, 0.21, 7.46]}
          userData={{ name: 'Wall' }}
        />
        <mesh
          name="Cube054"
          geometry={nodes.Cube054.geometry}
          material={nodes.Cube054.material}
          position={[-30.2, 0.77, 2.49]}
          userData={{ name: 'Cube.054' }}
        />
        <mesh
          name="Cube129"
          geometry={nodes.Cube129.geometry}
          material={materials['Hotel-cinema']}
          position={[-24.51, 2.09, -8.3]}
          userData={{ name: 'Cube.129' }}
        />
        <mesh
          name="Street_lamps"
          geometry={nodes.Street_lamps.geometry}
          material={nodes.Street_lamps.material}
          position={[-19.35, 0.88, -2.34]}
          userData={{ name: 'Street lamps' }}
        />
        <mesh
          name="Text005"
          geometry={nodes.Text005.geometry}
          material={nodes.Text005.material}
          position={[-30.73, 1.04, 11.23]}
          userData={{ name: 'Text.005' }}
        />
        <mesh
          name="Cube028"
          geometry={nodes.Cube028.geometry}
          material={nodes.Cube028.material}
          position={[-28.93, 1.19, 2.49]}
          userData={{ name: 'Cube.028' }}
        />
        <group name="Cinema005" position={[-27.02, 2.3, -4.58]} userData={{ name: 'Cinema.005' }}>
          <mesh name="Cube028_1" geometry={nodes.Cube028_1.geometry} material={materials.Cinema} />
          <mesh
            name="Cube028_2"
            geometry={nodes.Cube028_2.geometry}
            material={materials['Interactive advetising cinema']}
          />
        </group>
        <mesh
          name="Cube086"
          geometry={nodes.Cube086.geometry}
          material={materials['Little cafe-Cinema']}
          position={[-29.56, 1.27, 6.28]}
          userData={{ name: 'Cube.086' }}
        />
        <mesh
          name="Text009"
          geometry={nodes.Text009.geometry}
          material={nodes.Text009.material}
          position={[-30.59, 2.29, 11.21]}
          userData={{ name: 'Text.009' }}
        />
        <mesh
          name="Cube027"
          geometry={nodes.Cube027.geometry}
          material={nodes.Cube027.material}
          position={[-28.07, 0.35, -0.5]}
          userData={{ name: 'Cube.027' }}
        />
        <mesh
          name="Street_lamps001"
          geometry={nodes.Street_lamps001.geometry}
          material={nodes.Street_lamps001.material}
          position={[-19.49, 0.88, -0.61]}
          userData={{ name: 'Street lamps.001' }}
        />
        <mesh
          name="Street_lamps002"
          geometry={nodes.Street_lamps002.geometry}
          material={nodes.Street_lamps002.material}
          position={[-24.56, 0.88, 1.59]}
          userData={{ name: 'Street lamps.002' }}
        />
        <mesh
          name="Street_lamps003"
          geometry={nodes.Street_lamps003.geometry}
          material={nodes.Street_lamps003.material}
          position={[-31.08, 0.88, -1.69]}
          userData={{ name: 'Street lamps.003' }}
        />
        <mesh
          name="Street_lamps004"
          geometry={nodes.Street_lamps004.geometry}
          material={nodes.Street_lamps004.material}
          position={[-32.66, 1.26, 8.02]}
          userData={{ name: 'Street lamps.004' }}
        />
        <mesh
          name="Street_lamps005"
          geometry={nodes.Street_lamps005.geometry}
          material={nodes.Street_lamps005.material}
          position={[-21.76, 0.88, -7.12]}
          userData={{ name: 'Street lamps.005' }}
        />
        <mesh
          name="Street_lamps006"
          geometry={nodes.Street_lamps006.geometry}
          material={nodes.Street_lamps006.material}
          position={[-28.37, 0.88, 11.09]}
          userData={{ name: 'Street lamps.006' }}
        />
        <mesh
          name="Cube060"
          geometry={nodes.Cube060.geometry}
          material={nodes.Cube060.material}
          position={[-19.39, 0.35, -1.53]}
          userData={{ name: 'Cube.060' }}
        />
        <mesh
          name="Cube088"
          geometry={nodes.Cube088.geometry}
          material={nodes.Cube088.material}
          position={[-33.38, 0.35, 13.92]}
          userData={{ name: 'Cube.088' }}
        />
        <mesh
          name="Cube146"
          geometry={nodes.Cube146.geometry}
          material={nodes.Cube146.material}
          position={[-25.98, 0.28, 0.54]}
          userData={{ name: 'Cube.146' }}
        />
        <mesh
          name="Cube147"
          geometry={nodes.Cube147.geometry}
          material={nodes.Cube147.material}
          position={[-26.56, 0.36, 0.58]}
          userData={{ name: 'Cube.147' }}
        />
        <mesh
          name="Cube148"
          geometry={nodes.Cube148.geometry}
          material={nodes.Cube148.material}
          position={[-25.99, 0.36, -0.03]}
          userData={{ name: 'Cube.148' }}
        />
        <mesh
          name="Cube149"
          geometry={nodes.Cube149.geometry}
          material={nodes.Cube149.material}
          position={[-25.42, 0.36, 0.59]}
          userData={{ name: 'Cube.149' }}
        />
        <mesh
          name="Cube150"
          geometry={nodes.Cube150.geometry}
          material={nodes.Cube150.material}
          position={[-25.96, 0.36, 1.13]}
          userData={{ name: 'Cube.150' }}
        />
        <mesh
          name="Ground_cinema"
          geometry={nodes.Ground_cinema.geometry}
          material={nodes.Ground_cinema.material}
          position={[-26.89, 0.08, 0.02]}
          userData={{ name: 'Ground cinema' }}
        />
        <mesh
          name="Cube012"
          geometry={nodes.Cube012.geometry}
          material={nodes.Cube012.material}
          position={[-23.15, 0.28, -2.33]}
          userData={{ name: 'Cube.012' }}
        />
        <mesh
          name="Cube014"
          geometry={nodes.Cube014.geometry}
          material={nodes.Cube014.material}
          position={[-23.72, 0.36, -2.3]}
          userData={{ name: 'Cube.014' }}
        />
        <mesh
          name="Cube055"
          geometry={nodes.Cube055.geometry}
          material={nodes.Cube055.material}
          position={[-23.15, 0.36, -2.9]}
          userData={{ name: 'Cube.055' }}
        />
        <mesh
          name="Cube061"
          geometry={nodes.Cube061.geometry}
          material={nodes.Cube061.material}
          position={[-22.58, 0.36, -2.28]}
          userData={{ name: 'Cube.061' }}
        />
        <mesh
          name="Cube067"
          geometry={nodes.Cube067.geometry}
          material={nodes.Cube067.material}
          position={[-23.12, 0.36, -1.74]}
          userData={{ name: 'Cube.067' }}
        />
        <mesh
          name="Cube072"
          geometry={nodes.Cube072.geometry}
          material={nodes.Cube072.material}
          position={[-23.92, 0.28, -5.13]}
          userData={{ name: 'Cube.072' }}
        />
        <mesh
          name="Cube073"
          geometry={nodes.Cube073.geometry}
          material={nodes.Cube073.material}
          position={[-24.49, 0.36, -5.09]}
          userData={{ name: 'Cube.073' }}
        />
        <mesh
          name="Cube075"
          geometry={nodes.Cube075.geometry}
          material={nodes.Cube075.material}
          position={[-23.92, 0.36, -5.69]}
          userData={{ name: 'Cube.075' }}
        />
        <mesh
          name="Cube076"
          geometry={nodes.Cube076.geometry}
          material={nodes.Cube076.material}
          position={[-23.35, 0.36, -5.08]}
          userData={{ name: 'Cube.076' }}
        />
        <mesh
          name="Cube077"
          geometry={nodes.Cube077.geometry}
          material={nodes.Cube077.material}
          position={[-23.89, 0.36, -4.53]}
          userData={{ name: 'Cube.077' }}
        />
        <mesh
          name="Cube062"
          geometry={nodes.Cube062.geometry}
          material={nodes.Cube062.material}
          position={[-29.3, 0.28, 11.39]}
          userData={{ name: 'Cube.062' }}
        />
        <mesh
          name="Cube065"
          geometry={nodes.Cube065.geometry}
          material={nodes.Cube065.material}
          position={[-29.88, 0.36, 11.43]}
          userData={{ name: 'Cube.065' }}
        />
        <mesh
          name="Cube066"
          geometry={nodes.Cube066.geometry}
          material={nodes.Cube066.material}
          position={[-29.31, 0.36, 10.82]}
          userData={{ name: 'Cube.066' }}
        />
        <mesh
          name="Cube068"
          geometry={nodes.Cube068.geometry}
          material={nodes.Cube068.material}
          position={[-28.74, 0.36, 11.44]}
          userData={{ name: 'Cube.068' }}
        />
        <mesh
          name="Cube071"
          geometry={nodes.Cube071.geometry}
          material={nodes.Cube071.material}
          position={[-29.27, 0.36, 11.98]}
          userData={{ name: 'Cube.071' }}
        />
        <mesh
          name="Cube081"
          geometry={nodes.Cube081.geometry}
          material={nodes.Cube081.material}
          position={[-30.76, 0.28, 0.18]}
          userData={{ name: 'Cube.081' }}
        />
        <mesh
          name="Cube082"
          geometry={nodes.Cube082.geometry}
          material={nodes.Cube082.material}
          position={[-31.34, 0.36, 0.21]}
          userData={{ name: 'Cube.082' }}
        />
        <mesh
          name="Cube083"
          geometry={nodes.Cube083.geometry}
          material={nodes.Cube083.material}
          position={[-30.76, 0.36, -0.39]}
          userData={{ name: 'Cube.083' }}
        />
        <mesh
          name="Cube084"
          geometry={nodes.Cube084.geometry}
          material={nodes.Cube084.material}
          position={[-30.2, 0.36, 0.23]}
          userData={{ name: 'Cube.084' }}
        />
        <mesh
          name="Cube085"
          geometry={nodes.Cube085.geometry}
          material={nodes.Cube085.material}
          position={[-30.73, 0.36, 0.77]}
          userData={{ name: 'Cube.085' }}
        />
        <mesh
          name="Cube091"
          geometry={nodes.Cube091.geometry}
          material={nodes.Cube091.material}
          position={[-28.41, 0.28, 4.35]}
          userData={{ name: 'Cube.091' }}
        />
        <mesh
          name="Cube093"
          geometry={nodes.Cube093.geometry}
          material={nodes.Cube093.material}
          position={[-28.99, 0.36, 4.38]}
          userData={{ name: 'Cube.093' }}
        />
        <mesh
          name="Cube095"
          geometry={nodes.Cube095.geometry}
          material={nodes.Cube095.material}
          position={[-28.41, 0.36, 3.78]}
          userData={{ name: 'Cube.095' }}
        />
        <mesh
          name="Cube098"
          geometry={nodes.Cube098.geometry}
          material={nodes.Cube098.material}
          position={[-27.85, 0.36, 4.39]}
          userData={{ name: 'Cube.098' }}
        />
        <mesh
          name="Cube099"
          geometry={nodes.Cube099.geometry}
          material={nodes.Cube099.material}
          position={[-28.38, 0.36, 4.94]}
          userData={{ name: 'Cube.099' }}
        />
        <mesh
          name="House009"
          geometry={nodes.House009.geometry}
          material={nodes.House009.material}
          position={[-7.44, 0.52, 3.27]}
          userData={{ name: 'House.009' }}
        />
        <mesh
          name="House005"
          geometry={nodes.House005.geometry}
          material={nodes.House005.material}
          position={[-11.12, 0.64, 3.05]}
          userData={{ name: 'House.005' }}
        />
        <mesh
          name="House006"
          geometry={nodes.House006.geometry}
          material={nodes.House006.material}
          position={[-10.99, 0.51, 6.77]}
          userData={{ name: 'House.006' }}
        />
        <mesh
          name="House016"
          geometry={nodes.House016.geometry}
          material={nodes.House016.material}
          position={[-6.91, 0.6, 6.71]}
          userData={{ name: 'House.016' }}
        />
        <mesh
          name="House021"
          geometry={nodes.House021.geometry}
          material={nodes.House021.material}
          position={[-7.06, 0.64, -0.1]}
          userData={{ name: 'House.021' }}
        />
        <mesh
          name="Cube041"
          geometry={nodes.Cube041.geometry}
          material={nodes.Cube041.material}
          position={[-11.21, 0.24, 8.62]}
          userData={{ name: 'Cube.041' }}
        />
        <mesh
          name="Cube042"
          geometry={nodes.Cube042.geometry}
          material={nodes.Cube042.material}
          position={[-11.15, 0.24, 4.63]}
          userData={{ name: 'Cube.042' }}
        />
        <mesh
          name="Cube052"
          geometry={nodes.Cube052.geometry}
          material={nodes.Cube052.material}
          position={[-7.1, 0.24, 8.14]}
          userData={{ name: 'Cube.052' }}
        />
        <mesh
          name="Cube053"
          geometry={nodes.Cube053.geometry}
          material={nodes.Cube053.material}
          position={[-7.12, 0.24, 5.27]}
          userData={{ name: 'Cube.053' }}
        />
        <mesh
          name="Cube057"
          geometry={nodes.Cube057.geometry}
          material={nodes.Cube057.material}
          position={[-7.15, 0.24, 1.34]}
          userData={{ name: 'Cube.057' }}
        />
        <mesh
          name="Cube058"
          geometry={nodes.Cube058.geometry}
          material={nodes.Cube058.material}
          position={[-7.1, 0.24, -1.39]}
          userData={{ name: 'Cube.058' }}
        />
        <mesh
          name="Cube059"
          geometry={nodes.Cube059.geometry}
          material={nodes.Cube059.material}
          position={[-11.1, 0.24, 1.63]}
          userData={{ name: 'Cube.059' }}
        />
        <mesh
          name="Ground_Visitor_center"
          geometry={nodes.Ground_Visitor_center.geometry}
          material={nodes.Ground_Visitor_center.material}
          position={[-9.48, 0.05, -0.26]}
          userData={{ name: 'Ground Visitor center' }}
        />
        <mesh
          name="Grass003"
          geometry={nodes.Grass003.geometry}
          material={nodes.Grass003.material}
          position={[-9.64, 0.13, 3.89]}
          userData={{ name: 'Grass.003' }}
        />
        <mesh
          name="Tree010"
          geometry={nodes.Tree010.geometry}
          material={nodes.Tree010.material}
          position={[-11.6, 1.02, 0.62]}
          userData={{ name: 'Tree.010' }}
        />
        <mesh
          name="Tree011"
          geometry={nodes.Tree011.geometry}
          material={nodes.Tree011.material}
          position={[-3.79, 1.37, 3.1]}
          userData={{ name: 'Tree.011' }}
        />
        <mesh
          name="Tree012"
          geometry={nodes.Tree012.geometry}
          material={nodes.Tree012.material}
          position={[-3.58, 1.37, -0.9]}
          userData={{ name: 'Tree.012' }}
        />
        <mesh
          name="Tree014"
          geometry={nodes.Tree014.geometry}
          material={nodes.Tree014.material}
          position={[-3.66, 1.37, 6.85]}
          userData={{ name: 'Tree.014' }}
        />
        <mesh
          name="Cube015"
          geometry={nodes.Cube015.geometry}
          material={nodes.Cube015.material}
          position={[-12.39, 0.11, -11.12]}
          userData={{ name: 'Cube.015' }}
        />
        <mesh
          name="Cube069"
          geometry={nodes.Cube069.geometry}
          material={nodes.Cube069.material}
          position={[-8.25, 1.76, -4.74]}
          userData={{ name: 'Cube.069' }}
        />
        <mesh
          name="Cube070"
          geometry={nodes.Cube070.geometry}
          material={nodes.Cube070.material}
          position={[-8.25, 0.43, -4.74]}
          userData={{ name: 'Cube.070' }}
        />
        <mesh
          name="Cylinder012"
          geometry={nodes.Cylinder012.geometry}
          material={nodes.Cylinder012.material}
          position={[-9.89, -0.09, -4.4]}
          userData={{ name: 'Cylinder.012' }}
        />
        <mesh
          name="Cylinder013"
          geometry={nodes.Cylinder013.geometry}
          material={nodes.Cylinder013.material}
          position={[-5.72, 0.11, -9.1]}
          userData={{ name: 'Cylinder.013' }}
        />
        <mesh
          name="Plane002"
          geometry={nodes.Plane002.geometry}
          material={nodes.Plane002.material}
          position={[-6.01, 0.62, -10.67]}
          userData={{ name: 'Plane.002' }}
        />
        <mesh
          name="Plane003"
          geometry={nodes.Plane003.geometry}
          material={nodes.Plane003.material}
          position={[-4.9, 1.6, -6.5]}
          userData={{ name: 'Plane.003' }}
        />
        <mesh
          name="Plane004"
          geometry={nodes.Plane004.geometry}
          material={nodes.Plane004.material}
          position={[-4.88, 0.58, -6.5]}
          userData={{ name: 'Plane.004' }}
        />
        <mesh
          name="Text007"
          geometry={nodes.Text007.geometry}
          material={nodes.Text007.material}
          position={[-5.61, 1.53, -10.17]}
          userData={{ name: 'Text.007' }}
        />
        <mesh
          name="Text013"
          geometry={nodes.Text013.geometry}
          material={nodes.Text013.material}
          position={[-8.13, 2.69, -4.67]}
          userData={{ name: 'Text.013' }}
        />
        <mesh
          name="Vert004"
          geometry={nodes.Vert004.geometry}
          material={nodes.Vert004.material}
          position={[-4.82, 0.44, -8.29]}
          userData={{ name: 'Vert.004' }}
        />
        <mesh
          name="Cube030"
          geometry={nodes.Cube030.geometry}
          material={nodes.Cube030.material}
          position={[-5.51, 0.48, -7.98]}
          userData={{ name: 'Cube.030' }}
        />
        <mesh
          name="Cube032"
          geometry={nodes.Cube032.geometry}
          material={nodes.Cube032.material}
          position={[-4.83, 1.42, -10.51]}
          userData={{ name: 'Cube.032' }}
        />
        <mesh
          name="Text001"
          geometry={nodes.Text001.geometry}
          material={nodes.Text001.material}
          position={[-8.37, 2.69, -4.8]}
          userData={{ name: 'Text.001' }}
        />
        <mesh
          name="Vert001"
          geometry={nodes.Vert001.geometry}
          material={nodes.Vert001.material}
          position={[-4.86, 0.44, -7.55]}
          userData={{ name: 'Vert.001' }}
        />
        <mesh
          name="Vert002"
          geometry={nodes.Vert002.geometry}
          material={nodes.Vert002.material}
          position={[-4.82, 0.44, -5.42]}
          userData={{ name: 'Vert.002' }}
        />
        <mesh
          name="Vert006"
          geometry={nodes.Vert006.geometry}
          material={nodes.Vert006.material}
          position={[-4.86, 0.44, -4.68]}
          userData={{ name: 'Vert.006' }}
        />
        <mesh
          name="Cube029"
          geometry={nodes.Cube029.geometry}
          material={nodes.Cube029.material}
          position={[-4.28, 0.48, -7.98]}
          userData={{ name: 'Cube.029' }}
        />
        <mesh
          name="Cube031"
          geometry={nodes.Cube031.geometry}
          material={nodes.Cube031.material}
          position={[-5.51, 0.48, -5.13]}
          userData={{ name: 'Cube.031' }}
        />
        <mesh
          name="Cube037"
          geometry={nodes.Cube037.geometry}
          material={nodes.Cube037.material}
          position={[-4.28, 0.48, -5.13]}
          userData={{ name: 'Cube.037' }}
        />
        <mesh
          name="Ground_Gas_station"
          geometry={nodes.Ground_Gas_station.geometry}
          material={nodes.Ground_Gas_station.material}
          position={[-5.96, 0.09, -9.84]}
          userData={{ name: 'Ground Gas station' }}
        />
        <mesh
          name="Poste003"
          geometry={nodes.Poste003.geometry}
          material={nodes.Poste003.material}
          position={[-6.67, 1.26, -3.76]}
          userData={{ name: 'Poste.003' }}
        />
        <mesh
          name="Poste004"
          geometry={nodes.Poste004.geometry}
          material={nodes.Poste004.material}
          position={[-2.99, 1.26, -3.68]}
          userData={{ name: 'Poste.004' }}
        />
        <mesh
          name="Car_2002"
          geometry={nodes.Car_2002.geometry}
          material={nodes.Car_2002.material}
          position={[-8.52, 0.23, -6.9]}
          userData={{ name: 'Car 2.002' }}
        />
        <mesh
          name="Taxi"
          geometry={nodes.Taxi.geometry}
          material={nodes.Taxi.material}
          position={[-6.38, 0.17, -6.24]}
          userData={{ name: 'Taxi' }}
        />
        <mesh
          name="Truck"
          geometry={nodes.Truck.geometry}
          material={nodes.Truck.material}
          position={[-10.97, 0.27, -7.41]}
          userData={{ name: 'Truck' }}
        />
        <mesh
          name="Truck001"
          geometry={nodes.Truck001.geometry}
          material={nodes.Truck001.material}
          position={[-10.97, 0.27, -8.37]}
          userData={{ name: 'Truck.001' }}
        />
        <mesh
          name="Text002"
          geometry={nodes.Text002.geometry}
          material={nodes.Text002.material}
          position={[-4.84, 1.85, -4.8]}
          userData={{ name: 'Text.002' }}
        />
        <mesh
          name="Cube016"
          geometry={nodes.Cube016.geometry}
          material={nodes.Cube016.material}
          position={[-12.39, 0.11, -8.11]}
          userData={{ name: 'Cube.016' }}
        />
        <mesh
          name="Truck005"
          geometry={nodes.Truck005.geometry}
          material={nodes.Truck005.material}
          position={[-10.97, 0.27, -11.53]}
          userData={{ name: 'Truck.005' }}
        />
        <mesh
          name="Car_2004"
          geometry={nodes.Car_2004.geometry}
          material={nodes.Car_2004.material}
          position={[-11.27, 0.23, -10.51]}
          userData={{ name: 'Car 2.004' }}
        />
        <mesh
          name="euro_symbol"
          geometry={nodes.euro_symbol.geometry}
          material={nodes.euro_symbol.material}
          position={[-8.63, 1.57, -4.68]}
          userData={{ name: 'euro_symbol' }}
        />
        <mesh
          name="euro_symbol001"
          geometry={nodes.euro_symbol001.geometry}
          material={nodes.euro_symbol001.material}
          position={[-8.51, 1.57, -4.68]}
          userData={{ name: 'euro_symbol.001' }}
        />
        <mesh
          name="euro_symbol002"
          geometry={nodes.euro_symbol002.geometry}
          material={nodes.euro_symbol002.material}
          position={[-8.39, 1.57, -4.68]}
          userData={{ name: 'euro_symbol.002' }}
        />
        <mesh
          name="euro_symbol003"
          geometry={nodes.euro_symbol003.geometry}
          material={nodes.euro_symbol003.material}
          position={[-8.14, 1.57, -4.68]}
          userData={{ name: 'euro_symbol.003' }}
        />
        <mesh
          name="euro_symbol004"
          geometry={nodes.euro_symbol004.geometry}
          material={nodes.euro_symbol004.material}
          position={[-8.02, 1.57, -4.68]}
          userData={{ name: 'euro_symbol.004' }}
        />
        <mesh
          name="euro_symbol005"
          geometry={nodes.euro_symbol005.geometry}
          material={nodes.euro_symbol005.material}
          position={[-7.9, 1.57, -4.68]}
          userData={{ name: 'euro_symbol.005' }}
        />
        <mesh
          name="euro_symbol006"
          geometry={nodes.euro_symbol006.geometry}
          material={nodes.euro_symbol006.material}
          position={[-8.63, 1.88, -4.68]}
          userData={{ name: 'euro_symbol.006' }}
        />
        <mesh
          name="euro_symbol007"
          geometry={nodes.euro_symbol007.geometry}
          material={nodes.euro_symbol007.material}
          position={[-8.51, 1.88, -4.68]}
          userData={{ name: 'euro_symbol.007' }}
        />
        <mesh
          name="euro_symbol008"
          geometry={nodes.euro_symbol008.geometry}
          material={nodes.euro_symbol008.material}
          position={[-8.39, 1.88, -4.68]}
          userData={{ name: 'euro_symbol.008' }}
        />
        <mesh
          name="euro_symbol009"
          geometry={nodes.euro_symbol009.geometry}
          material={nodes.euro_symbol009.material}
          position={[-8.14, 1.88, -4.68]}
          userData={{ name: 'euro_symbol.009' }}
        />
        <mesh
          name="euro_symbol010"
          geometry={nodes.euro_symbol010.geometry}
          material={nodes.euro_symbol010.material}
          position={[-8.02, 1.88, -4.68]}
          userData={{ name: 'euro_symbol.010' }}
        />
        <mesh
          name="euro_symbol011"
          geometry={nodes.euro_symbol011.geometry}
          material={nodes.euro_symbol011.material}
          position={[-7.9, 1.88, -4.68]}
          userData={{ name: 'euro_symbol.011' }}
        />
        <mesh
          name="euro_symbol012"
          geometry={nodes.euro_symbol012.geometry}
          material={nodes.euro_symbol012.material}
          position={[-8.63, 1.25, -4.68]}
          userData={{ name: 'euro_symbol.012' }}
        />
        <mesh
          name="euro_symbol013"
          geometry={nodes.euro_symbol013.geometry}
          material={nodes.euro_symbol013.material}
          position={[-8.51, 1.25, -4.68]}
          userData={{ name: 'euro_symbol.013' }}
        />
        <mesh
          name="euro_symbol014"
          geometry={nodes.euro_symbol014.geometry}
          material={nodes.euro_symbol014.material}
          position={[-8.39, 1.25, -4.68]}
          userData={{ name: 'euro_symbol.014' }}
        />
        <mesh
          name="euro_symbol015"
          geometry={nodes.euro_symbol015.geometry}
          material={nodes.euro_symbol015.material}
          position={[-8.14, 1.25, -4.68]}
          userData={{ name: 'euro_symbol.015' }}
        />
        <mesh
          name="euro_symbol016"
          geometry={nodes.euro_symbol016.geometry}
          material={nodes.euro_symbol016.material}
          position={[-8.02, 1.25, -4.68]}
          userData={{ name: 'euro_symbol.016' }}
        />
        <mesh
          name="euro_symbol017"
          geometry={nodes.euro_symbol017.geometry}
          material={nodes.euro_symbol017.material}
          position={[-7.9, 1.25, -4.68]}
          userData={{ name: 'euro_symbol.017' }}
        />
        <mesh
          name="Factory_1"
          geometry={nodes.Factory_1.geometry}
          material={nodes.Factory_1.material}
          position={[14.79, 1.67, -6.33]}
          userData={{ name: 'Factory 1' }}
        />
        <mesh
          name="Factory_2"
          geometry={nodes.Factory_2.geometry}
          material={nodes.Factory_2.material}
          position={[18.69, 0.76, -6.15]}
          userData={{ name: 'Factory 2' }}
          style={{ cursor: 'pointer' }}
        />
        <mesh
          ref={Factory_3}
          name="Factory_3"
          geometry={nodes.Factory_3.geometry}
          material={nodes.Factory_3.material}
          position={[22.51, 1.16, -6.18]}
          userData={{ name: 'Factory 3' }}
        />
        <mesh
          name="Wall_factory"
          geometry={nodes.Wall_factory.geometry}
          material={nodes.Wall_factory.material}
          position={[17.03, 0.11, -5.49]}
          userData={{ name: 'Wall factory' }}
        />
        <mesh
          name="Isla003"
          geometry={nodes.Isla003.geometry}
          material={nodes.Isla003.material}
          position={[11.93, 0.33, -4.6]}
          userData={{ name: 'Isla.003' }}
        />
        <mesh
          name="Turbina_Heolica002"
          geometry={nodes.Turbina_Heolica002.geometry}
          material={nodes.Turbina_Heolica002.material}
          position={[14.57, 3.86, -9.47]}
          userData={{ name: 'Turbina Heolica.002' }}
        />
        <mesh
          name="Ground_Factory"
          geometry={nodes.Ground_Factory.geometry}
          material={nodes.Ground_Factory.material}
          position={[18.66, 0.05, -5.14]}
          userData={{ name: 'Ground Factory' }}
        />
        <mesh
          name="Truck004"
          geometry={nodes.Truck004.geometry}
          material={nodes.Truck004.material}
          position={[21.73, 0.27, -2.97]}
          userData={{ name: 'Truck.004' }}
        />
        <mesh
          name="Turbina_Heolica001"
          geometry={nodes.Turbina_Heolica001.geometry}
          material={nodes.Turbina_Heolica001.material}
          position={[21.1, 3.86, -9.34]}
          userData={{ name: 'Turbina Heolica.001' }}
        />
        <mesh
          name="Turbina_Heolica003"
          geometry={nodes.Turbina_Heolica003.geometry}
          material={nodes.Turbina_Heolica003.material}
          position={[14.7, 4.67, -9.25]}
          userData={{ name: 'Turbina Heolica.003' }}
        />
        <mesh
          name="Turbina_Heolica004"
          geometry={nodes.Turbina_Heolica004.geometry}
          material={nodes.Turbina_Heolica004.material}
          position={[21.18, 4.67, -9.1]}
          userData={{ name: 'Turbina Heolica.004' }}
        />
        <mesh
          name="Car_1004"
          geometry={nodes.Car_1004.geometry}
          material={nodes.Car_1004.material}
          position={[17.06, 0.19, -2.33]}
          userData={{ name: 'Car 1.004' }}
        />
        <mesh
          name="Car_2006"
          geometry={nodes.Car_2006.geometry}
          material={nodes.Car_2006.material}
          position={[17.99, 0.23, -2.38]}
          userData={{ name: 'Car 2.006' }}
        />
        <mesh
          name="Parking"
          geometry={nodes.Parking.geometry}
          material={nodes.Parking.material}
          position={[17.52, 0.11, -1.69]}
          userData={{ name: 'Parking' }}
        />
        <mesh
          name="Parking_2"
          geometry={nodes.Parking_2.geometry}
          material={nodes.Parking_2.material}
          position={[21.51, 0.11, -2.3]}
          userData={{ name: 'Parking 2' }}
        />
        <mesh
          name="Kantoor"
          geometry={nodes.Kantoor.geometry}
          material={nodes.Kantoor.material}
          position={[19.24, 1.55, -5.4]}
          userData={{ name: 'Kantoor' }}
        />
        <group name="Cube123" position={[-14.74, 0.79, -6.88]} userData={{ name: 'Cube.123' }}>
          <mesh name="Cube606" geometry={nodes.Cube606.geometry} material={nodes.Cube606.material} />
          <mesh
            name="Cube606_1"
            geometry={nodes.Cube606_1.geometry}
            material={materials['Interactive advertising Supermarkt']}
          />
        </group>
        <mesh
          name="Cube127"
          geometry={nodes.Cube127.geometry}
          material={nodes.Cube127.material}
          position={[-19.79, -0.1, -5.68]}
          userData={{ name: 'Cube.127' }}
        />
        <mesh
          name="Plane012"
          geometry={nodes.Plane012.geometry}
          material={nodes.Plane012.material}
          position={[-17.57, 1.34, -10]}
          userData={{ name: 'Plane.012' }}
        />
        <mesh
          name="Isla004"
          geometry={nodes.Isla004.geometry}
          material={nodes.Isla004.material}
          position={[-18.27, 0.09, -4.42]}
          userData={{ name: 'Isla.004' }}
        />
        <mesh
          name="Poste001"
          geometry={nodes.Poste001.geometry}
          material={nodes.Poste001.material}
          position={[-19.8, 1.26, -4.19]}
          userData={{ name: 'Poste.001' }}
        />
        <mesh
          name="Isla005"
          geometry={nodes.Isla005.geometry}
          material={nodes.Isla005.material}
          position={[-22.02, 0.09, -10.97]}
          userData={{ name: 'Isla.005' }}
        />
        <mesh
          name="Isla006"
          geometry={nodes.Isla006.geometry}
          material={nodes.Isla006.material}
          position={[-15.42, 0.09, -13.77]}
          userData={{ name: 'Isla.006' }}
        />
        <mesh
          name="Poste002"
          geometry={nodes.Poste002.geometry}
          material={nodes.Poste002.material}
          position={[-16.66, 1.26, -3.72]}
          userData={{ name: 'Poste.002' }}
        />
        <mesh
          name="Ground_Market"
          geometry={nodes.Ground_Market.geometry}
          material={nodes.Ground_Market.material}
          position={[-18.59, 0.07, -8.4]}
          userData={{ name: 'Ground Market' }}
        />
        <mesh
          name="Tree015"
          geometry={nodes.Tree015.geometry}
          material={nodes.Tree015.material}
          position={[-22.13, 1.37, -10.94]}
          userData={{ name: 'Tree.015' }}
        />
        <mesh
          name="Tree016"
          geometry={nodes.Tree016.geometry}
          material={nodes.Tree016.material}
          position={[-15.3, 1.37, -13.85]}
          userData={{ name: 'Tree.016' }}
        />
        <mesh
          name="Tree017"
          geometry={nodes.Tree017.geometry}
          material={nodes.Tree017.material}
          position={[-18.33, 1.24, -4.45]}
          userData={{ name: 'Tree.017' }}
        />
        <mesh
          name="Car_1003"
          geometry={nodes.Car_1003.geometry}
          material={nodes.Car_1003.material}
          position={[-19.43, 0.19, -7.02]}
          userData={{ name: 'Car 1.003' }}
        />
        <mesh
          name="Car_2003"
          geometry={nodes.Car_2003.geometry}
          material={nodes.Car_2003.material}
          position={[-19.37, 0.23, -6.09]}
          userData={{ name: 'Car 2.003' }}
        />
        <mesh
          name="Text003"
          geometry={nodes.Text003.geometry}
          material={nodes.Text003.material}
          position={[-17.67, 1.92, -8.74]}
          userData={{ name: 'Text.003' }}
        />
        <mesh
          name="Cylinder032"
          geometry={nodes.Cylinder032.geometry}
          material={nodes.Cylinder032.material}
          position={[-19.92, 0.2, -7.63]}
          userData={{ name: 'Cylinder.032' }}
        />
        <mesh
          name="Cube056"
          geometry={nodes.Cube056.geometry}
          material={nodes.Cube056.material}
          position={[-20.03, 0.11, -6.5]}
          userData={{ name: 'Cube.056' }}
        />
        <mesh
          name="Cylinder029"
          geometry={nodes.Cylinder029.geometry}
          material={nodes.Cylinder029.material}
          position={[-19.58, 0.2, -7.6]}
          userData={{ name: 'Cylinder.029' }}
        />
        <mesh
          name="Cylinder030"
          geometry={nodes.Cylinder030.geometry}
          material={nodes.Cylinder030.material}
          position={[-19.24, 0.2, -7.6]}
          userData={{ name: 'Cylinder.030' }}
        />
        <mesh
          name="Cylinder031"
          geometry={nodes.Cylinder031.geometry}
          material={nodes.Cylinder031.material}
          position={[-16.91, 0.2, -7.57]}
          userData={{ name: 'Cylinder.031' }}
        />
        <mesh
          name="Cylinder033"
          geometry={nodes.Cylinder033.geometry}
          material={nodes.Cylinder033.material}
          position={[-16.55, 0.2, -7.57]}
          userData={{ name: 'Cylinder.033' }}
        />
        <mesh
          name="Cylinder034"
          geometry={nodes.Cylinder034.geometry}
          material={nodes.Cylinder034.material}
          position={[-16.9, 0.2, -7.1]}
          userData={{ name: 'Cylinder.034' }}
        />
        <mesh
          name="Cylinder035"
          geometry={nodes.Cylinder035.geometry}
          material={nodes.Cylinder035.material}
          position={[-16.53, 0.2, -7.1]}
          userData={{ name: 'Cylinder.035' }}
        />
        <mesh
          name="Cylinder036"
          geometry={nodes.Cylinder036.geometry}
          material={nodes.Cylinder036.material}
          position={[-16.85, 0.2, -6.64]}
          userData={{ name: 'Cylinder.036' }}
        />
        <mesh
          name="Cylinder037"
          geometry={nodes.Cylinder037.geometry}
          material={nodes.Cylinder037.material}
          position={[-16.49, 0.2, -6.64]}
          userData={{ name: 'Cylinder.037' }}
        />
        <mesh
          name="Cube089"
          geometry={nodes.Cube089.geometry}
          material={nodes.Cube089.material}
          position={[-23.37, 0.35, 3.91]}
          userData={{ name: 'Cube.089' }}
        />
        <mesh
          name="Cube090"
          geometry={nodes.Cube090.geometry}
          material={nodes.Cube090.material}
          position={[-15.52, 0.35, 6.1]}
          userData={{ name: 'Cube.090' }}
        />
        <mesh
          name="Street_lamps007"
          geometry={nodes.Street_lamps007.geometry}
          material={nodes.Street_lamps007.material}
          position={[-17.15, 1.26, -2.41]}
          userData={{ name: 'Street lamps.007' }}
        />
        <mesh
          name="Tree003"
          geometry={nodes.Tree003.geometry}
          material={nodes.Tree003.material}
          position={[-25.39, 1.37, 4.41]}
          userData={{ name: 'Tree.003' }}
        />
        <mesh
          name="Tree005"
          geometry={nodes.Tree005.geometry}
          material={nodes.Tree005.material}
          position={[-25.08, 1.37, 9.92]}
          userData={{ name: 'Tree.005' }}
        />
        <mesh
          name="Tree007"
          geometry={nodes.Tree007.geometry}
          material={nodes.Tree007.material}
          position={[-16.21, 1.37, 0.64]}
          userData={{ name: 'Tree.007' }}
        />
        <mesh
          name="Tree008"
          geometry={nodes.Tree008.geometry}
          material={nodes.Tree008.material}
          position={[-21.05, 1.52, 2.39]}
          userData={{ name: 'Tree.008' }}
        />
        <mesh
          name="Tree009"
          geometry={nodes.Tree009.geometry}
          material={nodes.Tree009.material}
          position={[-16.53, 1.83, 8.17]}
          userData={{ name: 'Tree.009' }}
        />
        <mesh
          name="Cube009"
          geometry={nodes.Cube009.geometry}
          material={nodes.Cube009.material}
          position={[-17.16, 0.21, -1.67]}
          userData={{ name: 'Cube.009' }}
        />
        <mesh
          name="Cube167"
          geometry={nodes.Cube167.geometry}
          material={nodes.Cube167.material}
          position={[-15.37, 0.21, 4.04]}
          userData={{ name: 'Cube.167' }}
        />
        <mesh
          name="Cube168"
          geometry={nodes.Cube168.geometry}
          material={nodes.Cube168.material}
          position={[-25.58, 0.21, 7.07]}
          userData={{ name: 'Cube.168' }}
        />
        <mesh
          name="Cube169"
          geometry={nodes.Cube169.geometry}
          material={nodes.Cube169.material}
          position={[-20.3, 0.21, 9.39]}
          userData={{ name: 'Cube.169' }}
        />
        <mesh
          name="Cube173"
          geometry={nodes.Cube173.geometry}
          material={nodes.Cube173.material}
          position={[-19.47, 0.54, 7.57]}
          userData={{ name: 'Cube.173' }}
        />
        <mesh
          name="Cube175"
          geometry={nodes.Cube175.geometry}
          material={nodes.Cube175.material}
          position={[-19.96, 0.47, 4.89]}
          userData={{ name: 'Cube.175' }}
        />
        <mesh
          name="Cube171"
          geometry={nodes.Cube171.geometry}
          material={nodes.Cube171.material}
          position={[-18.93, 0.54, 2.63]}
          userData={{ name: 'Cube.171' }}
        />
        <mesh
          name="Cube172"
          geometry={nodes.Cube172.geometry}
          material={nodes.Cube172.material}
          position={[-17.53, 0.23, 4.9]}
          userData={{ name: 'Cube.172' }}
        />
        <mesh
          name="Cube174"
          geometry={nodes.Cube174.geometry}
          material={nodes.Cube174.material}
          position={[-19.27, 0.47, 4.88]}
          userData={{ name: 'Cube.174' }}
        />
        <mesh
          name="Ground_Park"
          geometry={nodes.Ground_Park.geometry}
          material={nodes.Ground_Park.material}
          position={[-21.65, 0.09, 3.51]}
          userData={{ name: 'Ground Park' }}
        />
        <mesh
          name="Street_lamps008"
          geometry={nodes.Street_lamps008.geometry}
          material={nodes.Street_lamps008.material}
          position={[-27.25, 0.88, 7.37]}
          userData={{ name: 'Street lamps.008' }}
        />
        <mesh
          name="Street_lamps009"
          geometry={nodes.Street_lamps009.geometry}
          material={nodes.Street_lamps009.material}
          position={[-14.47, 1.26, 3.61]}
          userData={{ name: 'Street lamps.009' }}
        />
        <mesh
          name="Street_lamps010"
          geometry={nodes.Street_lamps010.geometry}
          material={nodes.Street_lamps010.material}
          position={[-14.27, 1.26, 0.95]}
          userData={{ name: 'Street lamps.010' }}
        />
        <mesh
          name="Street_lamps011"
          geometry={nodes.Street_lamps011.geometry}
          material={nodes.Street_lamps011.material}
          position={[-14.37, 1.26, 7.81]}
          userData={{ name: 'Street lamps.011' }}
        />
        <mesh
          name="Grass001"
          geometry={nodes.Grass001.geometry}
          material={nodes.Grass001.material}
          position={[-18.9, 0.14, 5.37]}
          userData={{ name: 'Grass.001' }}
        />
        <mesh
          name="Cube179"
          geometry={nodes.Cube179.geometry}
          material={nodes.Cube179.material}
          position={[-21.89, 0.68, 5.08]}
          userData={{ name: 'Cube.179' }}
        />
        <mesh
          name="Cube183"
          geometry={nodes.Cube183.geometry}
          material={nodes.Cube183.material}
          position={[-23.59, 0.83, 5.63]}
          userData={{ name: 'Cube.183' }}
        />
        <mesh
          name="Cube191"
          geometry={nodes.Cube191.geometry}
          material={nodes.Cube191.material}
          position={[-22.08, 0.44, 6.56]}
          userData={{ name: 'Cube.191' }}
        />
        <mesh
          name="Cube194"
          geometry={nodes.Cube194.geometry}
          material={nodes.Cube194.material}
          position={[-23.34, 0.75, 7.88]}
          userData={{ name: 'Cube.194' }}
        />
        <mesh
          name="Cube197"
          geometry={nodes.Cube197.geometry}
          material={nodes.Cube197.material}
          position={[-21.06, 0.44, 7.62]}
          userData={{ name: 'Cube.197' }}
        />
        <mesh
          name="Cube092"
          geometry={nodes.Cube092.geometry}
          material={nodes.Cube092.material}
          position={[-15.55, -0.03, 15.34]}
          userData={{ name: 'Cube.092' }}
        />
        <mesh
          name="Cube108"
          geometry={nodes.Cube108.geometry}
          material={nodes.Cube108.material}
          position={[-15.25, 0.97, 15.63]}
          userData={{ name: 'Cube.108' }}
        />
        <mesh
          name="Cube109"
          geometry={nodes.Cube109.geometry}
          material={nodes.Cube109.material}
          position={[-15.2, 0.14, 16.84]}
          userData={{ name: 'Cube.109' }}
        />
        <group name="Cube063" position={[-17.63, -0.08, 19.18]} userData={{ name: 'Cube.063' }}>
          <mesh name="Cube135" geometry={nodes.Cube135.geometry} material={nodes.Cube135.material} />
          <mesh name="Cube135_1" geometry={nodes.Cube135_1.geometry} material={nodes.Cube135_1.material} />
        </group>
        <mesh
          name="Cube064"
          geometry={nodes.Cube064.geometry}
          material={nodes.Cube064.material}
          position={[-12.37, 0.89, 23.24]}
          userData={{ name: 'Cube.064' }}
        />
        <group name="Cube074" position={[-12.81, -0.08, 18.65]} userData={{ name: 'Cube.074' }}>
          <mesh name="Cube154_1" geometry={nodes.Cube154_1.geometry} material={nodes.Cube154_1.material} />
          <mesh name="Cube154_2" geometry={nodes.Cube154_2.geometry} material={nodes.Cube154_2.material} />
        </group>
        <mesh
          name="Street_lamps012"
          geometry={nodes.Street_lamps012.geometry}
          material={nodes.Street_lamps012.material}
          position={[-19.25, 1.26, 16.47]}
          userData={{ name: 'Street lamps.012' }}
        />
        <mesh
          name="Street_lamps013"
          geometry={nodes.Street_lamps013.geometry}
          material={nodes.Street_lamps013.material}
          position={[-12.02, 1.26, 15.75]}
          userData={{ name: 'Street lamps.013' }}
        />
        <mesh
          name="Cube176"
          geometry={nodes.Cube176.geometry}
          material={nodes.Cube176.material}
          position={[-19.02, 0.35, 15.49]}
          userData={{ name: 'Cube.176' }}
        />
        <mesh
          name="D0405211C58"
          geometry={nodes.D0405211C58.geometry}
          material={materials['Sail boat']}
          position={[-16.9, 0.56, 21.76]}
          userData={{ name: 'D0405211C58' }}
        />
        <mesh
          name="Lighthouses001"
          geometry={nodes.Lighthouses001.geometry}
          material={materials.Lighthouse}
          position={[-40.22, 4, 25.51]}
          userData={{ name: 'Lighthouses.001' }}
        />
        <mesh
          name="Road"
          geometry={nodes.Road.geometry}
          material={nodes.Road.material}
          position={[-10.2, 0.06, -0.19]}
          userData={{ name: 'Road' }}
        />
        <mesh
          name="Bridge"
          geometry={nodes.Bridge.geometry}
          material={materials.Bridge}
          position={[5.52, 0.4, -3.24]}
          userData={{ name: 'Bridge' }}
        />
        <mesh
          name="Road001"
          geometry={nodes.Road001.geometry}
          material={nodes.Road001.material}
          position={[-9.66, 0.08, -0.05]}
          userData={{ name: 'Road.001' }}
        />
        <mesh
          name="Car_1"
          geometry={nodes.Car_1.geometry}
          material={nodes.Car_1.material}
          position={[-9, 0.15, -13.21]}
          userData={{ name: 'Car 1' }}
        />
        <mesh
          name="Car_1001"
          geometry={nodes.Car_1001.geometry}
          material={nodes.Car_1001.material}
          position={[-13.47, 0.19, 5.07]}
          userData={{ name: 'Car 1.001' }}
        />
        <mesh
          name="Car_1002"
          geometry={nodes.Car_1002.geometry}
          material={nodes.Car_1002.material}
          position={[-27.79, 0.15, 6.08]}
          userData={{ name: 'Car 1.002' }}
        />
        <mesh
          name="Car_2"
          geometry={nodes.Car_2.geometry}
          material={nodes.Car_2.material}
          position={[0.72, 0.23, -3.24]}
          userData={{ name: 'Car 2' }}
        />
        <mesh
          name="Car_2001"
          geometry={nodes.Car_2001.geometry}
          material={nodes.Car_2001.material}
          position={[11.92, 0.23, 2.58]}
          userData={{ name: 'Car 2.001' }}
        />
        <mesh
          name="Taxi001"
          geometry={nodes.Taxi001.geometry}
          material={nodes.Taxi001.material}
          position={[-17.72, 0.2, -3.37]}
          userData={{ name: 'Taxi.001' }}
        />
        <mesh
          name="Taxi002"
          geometry={nodes.Taxi002.geometry}
          material={nodes.Taxi002.material}
          position={[-19.95, 0.2, 10.52]}
          userData={{ name: 'Taxi.002' }}
        />
        <mesh
          name="Truck003"
          geometry={nodes.Truck003.geometry}
          material={nodes.Truck003.material}
          position={[10.49, 0.27, -5.27]}
          userData={{ name: 'Truck.003' }}
        />
        <mesh
          name="Car_2005"
          geometry={nodes.Car_2005.geometry}
          material={nodes.Car_2005.material}
          position={[13.03, 0.23, 7]}
          userData={{ name: 'Car 2.005' }}
        />
        <mesh
          name="Taxi003"
          geometry={nodes.Taxi003.geometry}
          material={nodes.Taxi003.material}
          position={[12.98, 0.21, 7.49]}
          userData={{ name: 'Taxi.003' }}
        />
        <mesh
          name="Cube165"
          geometry={nodes.Cube165.geometry}
          material={nodes.Cube165.material}
          position={[-24.2, 0.14, 23.25]}
          userData={{ name: 'Cube.165' }}
        />
        <mesh
          name="Cylinder002"
          geometry={nodes.Cylinder002.geometry}
          material={nodes.Cylinder002.material}
          position={[-26.62, -0.3, 23.17]}
          userData={{ name: 'Cylinder.002' }}
        />
        {/* REUZENRAD GROUP */}
        <group name="Reuzenrad" userData={{ name: 'Reuzenrad' }}>
          <mesh
            name="Circle"
            geometry={nodes.Circle.geometry}
            material={nodes.Circle.material}
            position={[-27.56, 2.73, 23.08]}
            userData={{ name: 'Circle' }}
          />
          <mesh
            name="Cube078"
            geometry={nodes.Cube078.geometry}
            material={nodes.Cube078.material}
            position={[-27.56, 2.73, 23.08]}
            userData={{ name: 'Cube.078' }}
          />
          <mesh
            name="Cube020"
            geometry={nodes.Cube020.geometry}
            material={nodes.Cube020.material}
            position={[-27.55, 0.46, 22.62]}
            userData={{ name: 'Cube.020' }}
          />
          <mesh
            name="Cube021"
            geometry={nodes.Cube021.geometry}
            material={nodes.Cube021.material}
            position={[-27.55, 1.08, 21.49]}
            userData={{ name: 'Cube.021' }}
          />
          <mesh
            name="Cube022"
            geometry={nodes.Cube022.geometry}
            material={nodes.Cube022.material}
            position={[-27.55, 2.39, 20.87]}
            userData={{ name: 'Cube.022' }}
          />
          <mesh
            name="Cube023"
            geometry={nodes.Cube023.geometry}
            material={nodes.Cube023.material}
            position={[-27.55, 3.72, 21.14]}
            userData={{ name: 'Cube.023' }}
          />
          <mesh
            name="Cube024"
            geometry={nodes.Cube024.geometry}
            material={nodes.Cube024.material}
            position={[-27.55, 4.6, 22.14]}
            userData={{ name: 'Cube.024' }}
          />
          <mesh
            name="Cube025"
            geometry={nodes.Cube025.geometry}
            material={nodes.Cube025.material}
            position={[-27.55, 4.79, 23.36]}
            userData={{ name: 'Cube.025' }}
          />
          <mesh
            name="Cube004"
            geometry={nodes.Cube004.geometry}
            material={nodes.Cube004.material}
            position={[-27.55, 4.23, 24.57]}
            userData={{ name: 'Cube.004' }}
          />
          <mesh
            name="Cube006"
            geometry={nodes.Cube006.geometry}
            material={nodes.Cube006.material}
            position={[-27.55, 3.11, 25.19]}
            userData={{ name: 'Cube.006' }}
          />
          <mesh
            name="Cube007"
            geometry={nodes.Cube007.geometry}
            material={nodes.Cube007.material}
            position={[-27.55, 1.73, 25.1]}
            userData={{ name: 'Cube.007' }}
          />
          <mesh
            name="Cube017"
            geometry={nodes.Cube017.geometry}
            material={nodes.Cube017.material}
            position={[-27.55, 0.68, 24.13]}
            userData={{ name: 'Cube.017' }}
          />
        </group>
        {/* EINDE REUZENRAD  */}
        <mesh
          name="Cube079"
          geometry={nodes.Cube079.geometry}
          material={nodes.Cube079.material}
          position={[-27.56, 1.05, 23.08]}
          userData={{ name: 'Cube.079' }}
        />
        <mesh
          name="Cylinder"
          geometry={nodes.Cylinder.geometry}
          material={nodes.Cylinder.material}
          position={[-27.57, 2.74, 23.07]}
          userData={{ name: 'Cylinder' }}
        />
        <mesh
          name="Cube018"
          geometry={nodes.Cube018.geometry}
          material={nodes.Cube018.material}
          position={[-25.12, 0.71, 23.17]}
          userData={{ name: 'Cube.018' }}
        />
        <mesh
          name="Cube019"
          geometry={nodes.Cube019.geometry}
          material={nodes.Cube019.material}
          position={[-26.54, 0.14, 25.72]}
          userData={{ name: 'Cube.019' }}
        />

        <mesh
          name="Cube087"
          geometry={nodes.Cube087.geometry}
          material={nodes.Cube087.material}
          position={[-24.65, 0.35, 20.69]}
          userData={{ name: 'Cube.087' }}
        />
        <mesh
          name="Cube166"
          geometry={nodes.Cube166.geometry}
          material={nodes.Cube166.material}
          position={[-25.87, 0.26, 25.29]}
          userData={{ name: 'Cube.166' }}
        />
        <mesh
          name="Cube026"
          geometry={nodes.Cube026.geometry}
          material={nodes.Cube026.material}
          position={[-24.63, 0.21, 24.95]}
          userData={{ name: 'Cube.026' }}
        />
        <mesh
          name="Cube080"
          geometry={nodes.Cube080.geometry}
          material={nodes.Cube080.material}
          position={[-24.59, 0.21, 21.35]}
          userData={{ name: 'Cube.080' }}
        />
        <mesh
          name="Cube164"
          geometry={nodes.Cube164.geometry}
          material={nodes.Cube164.material}
          position={[-28.74, 0.14, 23.17]}
          userData={{ name: 'Cube.164' }}
        />
        <mesh
          name="Grass"
          geometry={nodes.Grass.geometry}
          material={nodes.Grass.material}
          position={[-13.87, 0.19, 3.46]}
          userData={{ name: 'Grass' }}
        />
        <mesh
          name="Water"
          geometry={nodes.Water.geometry}
          material={materials.Water}
          position={[0, 1.24, 0]}
          userData={{ name: 'Water' }}
        />
        <group name="Hoarding007" position={[-13.35, 2.5, -3.32]} userData={{ name: 'Hoarding.007' }}>
          <mesh name="Cube166_1" geometry={nodes.Cube166_1.geometry} material={nodes.Cube166_1.material} />
          <mesh name="Cube166_2" geometry={nodes.Cube166_2.geometry} material={nodes.Cube166_2.material} />
        </group>
        <group name="Hoarding002" position={[-32.85, 2.46, 17.46]} userData={{ name: 'Hoarding.002' }}>
          <mesh name="Cube256" geometry={nodes.Cube256.geometry} material={nodes.Cube256.material} />
          <mesh name="Cube256_1" geometry={nodes.Cube256_1.geometry} material={nodes.Cube256_1.material} />
        </group>
        <group
          name="Hoarding001"
          position={[-2.28, 2.5, 12.58]}
          rotation={[0, 0.7, 0]}
          userData={{ name: 'Hoarding.001' }}>
          <mesh name="Cube303" geometry={nodes.Cube303.geometry} material={nodes.Cube303.material} />
          <mesh name="Cube303_1" geometry={nodes.Cube303_1.geometry} material={nodes.Cube303_1.material} />
        </group>
        <mesh
          name="Ground_square"
          geometry={nodes.Ground_square.geometry}
          material={nodes.Ground_square.material}
          position={[-13.39, 0.09, -3.36]}
          userData={{ name: 'Ground square' }}
        />
        <mesh
          name="Cube151"
          geometry={nodes.Cube151.geometry}
          material={nodes.Cube151.material}
          position={[-14.9, 0.21, -3.71]}
          userData={{ name: 'Cube.151' }}
        />
        <mesh
          name="Cube152"
          geometry={nodes.Cube152.geometry}
          material={nodes.Cube152.material}
          position={[-11.97, 0.21, -3.67]}
          userData={{ name: 'Cube.152' }}
        />
        <mesh
          name="Cube153"
          geometry={nodes.Cube153.geometry}
          material={nodes.Cube153.material}
          position={[-13.7, 0.21, -4.79]}
          userData={{ name: 'Cube.153' }}
        />
        <mesh
          name="Cube154"
          geometry={nodes.Cube154.geometry}
          material={nodes.Cube154.material}
          position={[-13.02, 0.21, -4.76]}
          userData={{ name: 'Cube.154' }}
        />
        <mesh
          name="Cube155"
          geometry={nodes.Cube155.geometry}
          material={nodes.Cube155.material}
          position={[-13.71, 0.21, -1.9]}
          userData={{ name: 'Cube.155' }}
        />
        <mesh
          name="Cube156"
          geometry={nodes.Cube156.geometry}
          material={nodes.Cube156.material}
          position={[-13.03, 0.21, -1.88]}
          userData={{ name: 'Cube.156' }}
        />
        <mesh
          name="Cube157"
          geometry={nodes.Cube157.geometry}
          material={nodes.Cube157.material}
          position={[-11.96, 0.21, -2.92]}
          userData={{ name: 'Cube.157' }}
        />
        <mesh
          name="Cube158"
          geometry={nodes.Cube158.geometry}
          material={nodes.Cube158.material}
          position={[-14.89, 0.21, -2.95]}
          userData={{ name: 'Cube.158' }}
        />
        <mesh
          name="Tree018"
          geometry={nodes.Tree018.geometry}
          material={nodes.Tree018.material}
          position={[-14.63, 0.79, -4.63]}
          userData={{ name: 'Tree.018' }}
        />
        <mesh
          name="Tree019"
          geometry={nodes.Tree019.geometry}
          material={nodes.Tree019.material}
          position={[-14.7, 0.81, -2.16]}
          userData={{ name: 'Tree.019' }}
        />
        <mesh
          name="Tree020"
          geometry={nodes.Tree020.geometry}
          material={nodes.Tree020.material}
          position={[-12.19, 0.79, -4.63]}
          userData={{ name: 'Tree.020' }}
        />
        <mesh
          name="Tree021"
          geometry={nodes.Tree021.geometry}
          material={nodes.Tree021.material}
          position={[-12.22, 0.81, -2.01]}
          userData={{ name: 'Tree.021' }}
        />
        <group name="Grass002" position={[-13.48, 0.16, -3.12]} userData={{ name: 'Grass.002' }}>
          <mesh name="Plane002_1" geometry={nodes.Plane002_1.geometry} material={nodes.Plane002_1.material} />
          <mesh name="Plane002_2" geometry={nodes.Plane002_2.geometry} material={nodes.Plane002_2.material} />
        </group>
        <mesh
          name="Cube202"
          geometry={nodes.Cube202.geometry}
          material={nodes.Cube202.material}
          position={[13.27, 0.11, -0.32]}
          userData={{ name: 'Cube.202' }}
        />
        <mesh
          name="Cube094"
          geometry={nodes.Cube094.geometry}
          material={nodes.Cube094.material}
          position={[15.39, 0.33, 0.1]}
          userData={{ name: 'Cube.094' }}
        />
        <mesh
          name="Plane001"
          geometry={nodes.Plane001.geometry}
          material={nodes.Plane001.material}
          position={[14.81, 0.18, 0.89]}
          userData={{ name: 'Plane.001' }}
        />
        <mesh
          name="Cylinder008"
          geometry={nodes.Cylinder008.geometry}
          material={nodes.Cylinder008.material}
          position={[10.66, 0.12, 1.91]}
          userData={{ name: 'Cylinder.008' }}
        />
        <mesh
          name="Cube096"
          geometry={nodes.Cube096.geometry}
          material={nodes.Cube096.material}
          position={[13.33, 0.11, -0.75]}
          userData={{ name: 'Cube.096' }}
        />
        <mesh
          name="Cube097"
          geometry={nodes.Cube097.geometry}
          material={nodes.Cube097.material}
          position={[13.38, 0.11, 0.51]}
          userData={{ name: 'Cube.097' }}
        />
        <mesh
          name="Cube203"
          geometry={nodes.Cube203.geometry}
          material={nodes.Cube203.material}
          position={[13.27, 0.11, 0.1]}
          userData={{ name: 'Cube.203' }}
        />
        <mesh
          name="Plane005"
          geometry={nodes.Plane005.geometry}
          material={nodes.Plane005.material}
          position={[12.96, 0.18, 1.36]}
          userData={{ name: 'Plane.005' }}
        />
        <mesh
          name="Plane006"
          geometry={nodes.Plane006.geometry}
          material={nodes.Plane006.material}
          position={[17.04, 0.18, -0.62]}
          userData={{ name: 'Plane.006' }}
        />
        <mesh
          name="Cube204"
          geometry={nodes.Cube204.geometry}
          material={nodes.Cube204.material}
          position={[17.6, 0.33, 0.3]}
          userData={{ name: 'Cube.204' }}
        />
        <mesh
          name="Cylinder025"
          geometry={nodes.Cylinder025.geometry}
          material={nodes.Cylinder025.material}
          position={[11.07, 0.12, 1.91]}
          userData={{ name: 'Cylinder.025' }}
        />
        <mesh
          name="Cylinder038"
          geometry={nodes.Cylinder038.geometry}
          material={nodes.Cylinder038.material}
          position={[18.92, 0.13, -0.68]}
          userData={{ name: 'Cylinder.038' }}
        />
        <mesh
          name="Cylinder039"
          geometry={nodes.Cylinder039.geometry}
          material={nodes.Cylinder039.material}
          position={[19.07, 0.13, 1.29]}
          userData={{ name: 'Cylinder.039' }}
        />
        <mesh
          name="Cylinder040"
          geometry={nodes.Cylinder040.geometry}
          material={nodes.Cylinder040.material}
          position={[17.07, 0.03, 1.84]}
          userData={{ name: 'Cylinder.040' }}
        />
        <mesh
          name="Cylinder041"
          geometry={nodes.Cylinder041.geometry}
          material={nodes.Cylinder041.material}
          position={[14.66, 0.02, 1.88]}
          userData={{ name: 'Cylinder.041' }}
        />
        <mesh
          name="Cylinder042"
          geometry={nodes.Cylinder042.geometry}
          material={nodes.Cylinder042.material}
          position={[12.15, 0.06, 1.92]}
          userData={{ name: 'Cylinder.042' }}
        />
        <mesh
          name="Cylinder043"
          geometry={nodes.Cylinder043.geometry}
          material={nodes.Cylinder043.material}
          position={[12.54, 0.12, -0.05]}
          userData={{ name: 'Cylinder.043' }}
        />
        <mesh
          name="Cerca"
          geometry={nodes.Cerca.geometry}
          material={nodes.Cerca.material}
          position={[2.07, 0.12, -8.63]}
          userData={{ name: 'Cerca' }}
        />
        <mesh
          name="Cow"
          geometry={nodes.Cow.geometry}
          material={nodes.Cow.material}
          position={[1.89, 0.18, -9.24]}
          userData={{ name: 'Cow' }}
        />
        <mesh
          name="Farm"
          geometry={nodes.Farm.geometry}
          material={nodes.Farm.material}
          position={[2.11, 0.58, -11.15]}
          userData={{ name: 'Farm' }}
        />
        <mesh
          name="Heno"
          geometry={nodes.Heno.geometry}
          material={nodes.Heno.material}
          position={[1.73, 0.04, -8.81]}
          userData={{ name: 'Heno' }}
        />
        <mesh
          name="Cow001"
          geometry={nodes.Cow001.geometry}
          material={nodes.Cow001.material}
          position={[2.38, 0.18, -8.26]}
          rotation={[0, 0.97, 0]}
          userData={{ name: 'Cow.001' }}
        />
        <mesh
          name="Cow002"
          geometry={nodes.Cow002.geometry}
          material={nodes.Cow002.material}
          position={[3.41, 0.18, -8.89]}
          rotation={[0, -0.06, 0]}
          userData={{ name: 'Cow.002' }}
        />
        <mesh
          name="Cow003"
          geometry={nodes.Cow003.geometry}
          material={nodes.Cow003.material}
          position={[4.07, 0.18, -10.66]}
          rotation={[0, 0.31, 0]}
          userData={{ name: 'Cow.003' }}
        />
        <mesh
          name="Heno001"
          geometry={nodes.Heno001.geometry}
          material={nodes.Heno001.material}
          position={[2.62, 0.04, -9.1]}
          rotation={[0, 1.44, 0]}
          userData={{ name: 'Heno.001' }}
        />
        <mesh
          name="Heno002"
          geometry={nodes.Heno002.geometry}
          material={nodes.Heno002.material}
          position={[4.24, 0.04, -9.45]}
          rotation={[0, 1.44, 0]}
          userData={{ name: 'Heno.002' }}
        />
        <mesh
          name="Heno003"
          geometry={nodes.Heno003.geometry}
          material={nodes.Heno003.material}
          position={[3.85, 0.04, -11.24]}
          rotation={[0, 1.44, 0]}
          userData={{ name: 'Heno.003' }}
        />
        <mesh
          name="Heno004"
          geometry={nodes.Heno004.geometry}
          material={nodes.Heno004.material}
          position={[5.17, 0.04, -11.81]}
          rotation={[0, 0.3, 0]}
          userData={{ name: 'Heno.004' }}
        />
        <mesh
          name="Cow004"
          geometry={nodes.Cow004.geometry}
          material={nodes.Cow004.material}
          position={[4.78, 0.18, -10.15]}
          rotation={[0, -0.68, 0]}
          userData={{ name: 'Cow.004' }}
        />
        <mesh
          name="Cow005"
          geometry={nodes.Cow005.geometry}
          material={nodes.Cow005.material}
          position={[3.83, 0.18, -12.14]}
          rotation={[0, 1.33, 0]}
          userData={{ name: 'Cow.005' }}
        />
        <mesh
          name="Cow006"
          geometry={nodes.Cow006.geometry}
          material={nodes.Cow006.material}
          position={[3.78, 0.18, -10.12]}
          userData={{ name: 'Cow.006' }}
        />
        <mesh
          name="Tulip"
          geometry={nodes.Tulip.geometry}
          material={nodes.Tulip.material}
          position={[1.56, 0.17, -5.42]}
          userData={{
            name: 'Tulip',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip001"
          geometry={nodes.Tulip001.geometry}
          material={nodes.Tulip001.material}
          position={[1.95, 0.17, -5.4]}
          userData={{
            name: 'Tulip.001',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip002"
          geometry={nodes.Tulip002.geometry}
          material={nodes.Tulip002.material}
          position={[2.43, 0.17, -5.37]}
          userData={{
            name: 'Tulip.002',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip003"
          geometry={nodes.Tulip003.geometry}
          material={nodes.Tulip003.material}
          position={[2.42, 0.17, -5.84]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.003',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip004"
          geometry={nodes.Tulip004.geometry}
          material={nodes.Tulip004.material}
          position={[2.04, 0.17, -5.82]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.004',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip005"
          geometry={nodes.Tulip005.geometry}
          material={nodes.Tulip005.material}
          position={[1.55, 0.17, -5.8]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.005',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip006"
          geometry={nodes.Tulip006.geometry}
          material={nodes.Tulip006.material}
          position={[2.42, 0.17, -6.2]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.006',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip007"
          geometry={nodes.Tulip007.geometry}
          material={nodes.Tulip007.material}
          position={[2.04, 0.17, -6.18]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.007',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip008"
          geometry={nodes.Tulip008.geometry}
          material={nodes.Tulip008.material}
          position={[1.55, 0.17, -6.16]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.008',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip009"
          geometry={nodes.Tulip009.geometry}
          material={nodes.Tulip009.material}
          position={[1.54, 0.17, -6.46]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.009',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip010"
          geometry={nodes.Tulip010.geometry}
          material={nodes.Tulip010.material}
          position={[1.76, 0.17, -5.3]}
          userData={{
            name: 'Tulip.010',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip011"
          geometry={nodes.Tulip011.geometry}
          material={nodes.Tulip011.material}
          position={[1.75, 0.17, -5.69]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.011',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip012"
          geometry={nodes.Tulip012.geometry}
          material={nodes.Tulip012.material}
          position={[1.75, 0.17, -6.05]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.012',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip013"
          geometry={nodes.Tulip013.geometry}
          material={nodes.Tulip013.material}
          position={[1.74, 0.17, -6.34]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.013',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip014"
          geometry={nodes.Tulip014.geometry}
          material={nodes.Tulip014.material}
          position={[1.89, 0.17, -5.51]}
          userData={{
            name: 'Tulip.014',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip015"
          geometry={nodes.Tulip015.geometry}
          material={nodes.Tulip015.material}
          position={[1.88, 0.17, -5.9]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.015',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip016"
          geometry={nodes.Tulip016.geometry}
          material={nodes.Tulip016.material}
          position={[1.88, 0.17, -6.26]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.016',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip017"
          geometry={nodes.Tulip017.geometry}
          material={nodes.Tulip017.material}
          position={[1.87, 0.17, -6.56]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.017',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip018"
          geometry={nodes.Tulip018.geometry}
          material={nodes.Tulip018.material}
          position={[2.17, 0.17, -5.28]}
          userData={{
            name: 'Tulip.018',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip019"
          geometry={nodes.Tulip019.geometry}
          material={nodes.Tulip019.material}
          position={[2.16, 0.17, -5.67]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.019',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip020"
          geometry={nodes.Tulip020.geometry}
          material={nodes.Tulip020.material}
          position={[2.16, 0.17, -6.03]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.020',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip021"
          geometry={nodes.Tulip021.geometry}
          material={nodes.Tulip021.material}
          position={[2.14, 0.17, -6.32]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.021',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip022"
          geometry={nodes.Tulip022.geometry}
          material={nodes.Tulip022.material}
          position={[2.31, 0.17, -5.5]}
          userData={{
            name: 'Tulip.022',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip023"
          geometry={nodes.Tulip023.geometry}
          material={nodes.Tulip023.material}
          position={[2.3, 0.17, -5.97]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.023',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip024"
          geometry={nodes.Tulip024.geometry}
          material={nodes.Tulip024.material}
          position={[2.3, 0.17, -6.33]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.024',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip025"
          geometry={nodes.Tulip025.geometry}
          material={nodes.Tulip025.material}
          position={[2.47, 0.17, -5.56]}
          userData={{
            name: 'Tulip.025',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip026"
          geometry={nodes.Tulip026.geometry}
          material={nodes.Tulip026.material}
          position={[2.46, 0.17, -6.02]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.026',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip027"
          geometry={nodes.Tulip027.geometry}
          material={nodes.Tulip027.material}
          position={[2.46, 0.17, -6.38]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.027',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip028"
          geometry={nodes.Tulip028.geometry}
          material={nodes.Tulip028.material}
          position={[0.45, 0.17, -4.89]}
          userData={{
            name: 'Tulip.028',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip029"
          geometry={nodes.Tulip029.geometry}
          material={nodes.Tulip029.material}
          position={[0.83, 0.17, -4.87]}
          userData={{
            name: 'Tulip.029',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip030"
          geometry={nodes.Tulip030.geometry}
          material={nodes.Tulip030.material}
          position={[1.32, 0.17, -4.85]}
          userData={{
            name: 'Tulip.030',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip031"
          geometry={nodes.Tulip031.geometry}
          material={nodes.Tulip031.material}
          position={[1.31, 0.17, -5.31]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.031',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip034"
          geometry={nodes.Tulip034.geometry}
          material={nodes.Tulip034.material}
          position={[1.31, 0.17, -5.68]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.034',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip037"
          geometry={nodes.Tulip037.geometry}
          material={nodes.Tulip037.material}
          position={[0.43, 0.17, -5.94]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.037',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip038"
          geometry={nodes.Tulip038.geometry}
          material={nodes.Tulip038.material}
          position={[0.65, 0.17, -4.78]}
          userData={{
            name: 'Tulip.038',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip042"
          geometry={nodes.Tulip042.geometry}
          material={nodes.Tulip042.material}
          position={[0.78, 0.17, -4.99]}
          userData={{
            name: 'Tulip.042',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip046"
          geometry={nodes.Tulip046.geometry}
          material={nodes.Tulip046.material}
          position={[1.05, 0.17, -4.76]}
          userData={{
            name: 'Tulip.046',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip047"
          geometry={nodes.Tulip047.geometry}
          material={nodes.Tulip047.material}
          position={[1.04, 0.17, -5.15]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.047',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip050"
          geometry={nodes.Tulip050.geometry}
          material={nodes.Tulip050.material}
          position={[1.2, 0.17, -4.98]}
          userData={{
            name: 'Tulip.050',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip051"
          geometry={nodes.Tulip051.geometry}
          material={nodes.Tulip051.material}
          position={[1.19, 0.17, -5.44]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.051',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip052"
          geometry={nodes.Tulip052.geometry}
          material={nodes.Tulip052.material}
          position={[1.19, 0.17, -5.8]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.052',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip053"
          geometry={nodes.Tulip053.geometry}
          material={nodes.Tulip053.material}
          position={[1.36, 0.17, -5.04]}
          userData={{
            name: 'Tulip.053',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip054"
          geometry={nodes.Tulip054.geometry}
          material={nodes.Tulip054.material}
          position={[1.35, 0.17, -5.5]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.054',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip055"
          geometry={nodes.Tulip055.geometry}
          material={nodes.Tulip055.material}
          position={[1.35, 0.17, -5.86]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.055',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip056"
          geometry={nodes.Tulip056.geometry}
          material={nodes.Tulip056.material}
          position={[-0.73, 0.17, -1.27]}
          userData={{
            name: 'Tulip.056',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip057"
          geometry={nodes.Tulip057.geometry}
          material={nodes.Tulip057.material}
          position={[-0.35, 0.17, -1.25]}
          userData={{
            name: 'Tulip.057',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip058"
          geometry={nodes.Tulip058.geometry}
          material={nodes.Tulip058.material}
          position={[0.42, 0.17, -1.23]}
          userData={{
            name: 'Tulip.058',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip059"
          geometry={nodes.Tulip059.geometry}
          material={nodes.Tulip059.material}
          position={[0.39, 0.17, -1.69]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.059',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip060"
          geometry={nodes.Tulip060.geometry}
          material={nodes.Tulip060.material}
          position={[-0.26, 0.17, -1.68]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.060',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip061"
          geometry={nodes.Tulip061.geometry}
          material={nodes.Tulip061.material}
          position={[-0.97, 0.17, -1.66]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.061',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip062"
          geometry={nodes.Tulip062.geometry}
          material={nodes.Tulip062.material}
          position={[0.12, 0.17, -2.05]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.062',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip063"
          geometry={nodes.Tulip063.geometry}
          material={nodes.Tulip063.material}
          position={[-0.26, 0.17, -2.04]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.063',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip064"
          geometry={nodes.Tulip064.geometry}
          material={nodes.Tulip064.material}
          position={[-0.97, 0.17, -2.02]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.064',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip065"
          geometry={nodes.Tulip065.geometry}
          material={nodes.Tulip065.material}
          position={[-0.76, 0.17, -2.31]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.065',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip066"
          geometry={nodes.Tulip066.geometry}
          material={nodes.Tulip066.material}
          position={[-0.54, 0.17, -1.15]}
          userData={{
            name: 'Tulip.066',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip067"
          geometry={nodes.Tulip067.geometry}
          material={nodes.Tulip067.material}
          position={[-0.55, 0.17, -1.54]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.067',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip068"
          geometry={nodes.Tulip068.geometry}
          material={nodes.Tulip068.material}
          position={[-0.55, 0.17, -1.9]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.068',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip069"
          geometry={nodes.Tulip069.geometry}
          material={nodes.Tulip069.material}
          position={[-0.56, 0.17, -2.2]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.069',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip070"
          geometry={nodes.Tulip070.geometry}
          material={nodes.Tulip070.material}
          position={[-0.41, 0.17, -1.37]}
          userData={{
            name: 'Tulip.070',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip071"
          geometry={nodes.Tulip071.geometry}
          material={nodes.Tulip071.material}
          position={[-0.42, 0.17, -1.75]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.071',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip072"
          geometry={nodes.Tulip072.geometry}
          material={nodes.Tulip072.material}
          position={[-0.42, 0.17, -2.11]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.072',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip073"
          geometry={nodes.Tulip073.geometry}
          material={nodes.Tulip073.material}
          position={[-0.43, 0.17, -2.41]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.073',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip074"
          geometry={nodes.Tulip074.geometry}
          material={nodes.Tulip074.material}
          position={[-0.13, 0.17, -1.13]}
          userData={{
            name: 'Tulip.074',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip075"
          geometry={nodes.Tulip075.geometry}
          material={nodes.Tulip075.material}
          position={[-0.14, 0.17, -1.52]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.075',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip076"
          geometry={nodes.Tulip076.geometry}
          material={nodes.Tulip076.material}
          position={[-0.14, 0.17, -1.88]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.076',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip077"
          geometry={nodes.Tulip077.geometry}
          material={nodes.Tulip077.material}
          position={[-0.16, 0.17, -2.18]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.077',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip078"
          geometry={nodes.Tulip078.geometry}
          material={nodes.Tulip078.material}
          position={[0.01, 0.17, -1.36]}
          userData={{
            name: 'Tulip.078',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip079"
          geometry={nodes.Tulip079.geometry}
          material={nodes.Tulip079.material}
          position={[0, 0.17, -1.82]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.079',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip080"
          geometry={nodes.Tulip080.geometry}
          material={nodes.Tulip080.material}
          position={[0, 0.17, -2.18]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.080',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip081"
          geometry={nodes.Tulip081.geometry}
          material={nodes.Tulip081.material}
          position={[0.17, 0.17, -1.41]}
          userData={{
            name: 'Tulip.081',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip082"
          geometry={nodes.Tulip082.geometry}
          material={nodes.Tulip082.material}
          position={[0.16, 0.17, -1.87]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.082',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip083"
          geometry={nodes.Tulip083.geometry}
          material={nodes.Tulip083.material}
          position={[0.16, 0.17, -2.24]}
          rotation={[Math.PI, -0.09, Math.PI]}
          userData={{
            name: 'Tulip.083',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip084"
          geometry={nodes.Tulip084.geometry}
          material={nodes.Tulip084.material}
          position={[1.67, 0.17, -2.19]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.084',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip085"
          geometry={nodes.Tulip085.geometry}
          material={nodes.Tulip085.material}
          position={[1.3, 0.17, -2.24]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.085',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip086"
          geometry={nodes.Tulip086.geometry}
          material={nodes.Tulip086.material}
          position={[0.81, 0.17, -2.31]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.086',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip087"
          geometry={nodes.Tulip087.geometry}
          material={nodes.Tulip087.material}
          position={[0.78, 0.17, -1.84]}
          userData={{
            name: 'Tulip.087',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip088"
          geometry={nodes.Tulip088.geometry}
          material={nodes.Tulip088.material}
          position={[1.16, 0.17, -1.82]}
          userData={{
            name: 'Tulip.088',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip089"
          geometry={nodes.Tulip089.geometry}
          material={nodes.Tulip089.material}
          position={[1.65, 0.17, -1.8]}
          userData={{
            name: 'Tulip.089',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip090"
          geometry={nodes.Tulip090.geometry}
          material={nodes.Tulip090.material}
          position={[0.75, 0.17, -1.48]}
          userData={{
            name: 'Tulip.090',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip091"
          geometry={nodes.Tulip091.geometry}
          material={nodes.Tulip091.material}
          position={[1.13, 0.17, -1.46]}
          userData={{
            name: 'Tulip.091',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip092"
          geometry={nodes.Tulip092.geometry}
          material={nodes.Tulip092.material}
          position={[1.62, 0.17, -1.44]}
          userData={{
            name: 'Tulip.092',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip093"
          geometry={nodes.Tulip093.geometry}
          material={nodes.Tulip093.material}
          position={[1.61, 0.17, -1.14]}
          userData={{
            name: 'Tulip.093',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip094"
          geometry={nodes.Tulip094.geometry}
          material={nodes.Tulip094.material}
          position={[1.49, 0.17, -2.32]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.094',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip095"
          geometry={nodes.Tulip095.geometry}
          material={nodes.Tulip095.material}
          position={[1.46, 0.17, -1.93]}
          userData={{
            name: 'Tulip.095',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip096"
          geometry={nodes.Tulip096.geometry}
          material={nodes.Tulip096.material}
          position={[1.43, 0.17, -1.57]}
          userData={{
            name: 'Tulip.096',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip097"
          geometry={nodes.Tulip097.geometry}
          material={nodes.Tulip097.material}
          position={[1.42, 0.17, -1.28]}
          userData={{
            name: 'Tulip.097',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip098"
          geometry={nodes.Tulip098.geometry}
          material={nodes.Tulip098.material}
          position={[1.34, 0.17, -2.12]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.098',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip099"
          geometry={nodes.Tulip099.geometry}
          material={nodes.Tulip099.material}
          position={[1.31, 0.17, -1.73]}
          userData={{
            name: 'Tulip.099',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip100"
          geometry={nodes.Tulip100.geometry}
          material={nodes.Tulip100.material}
          position={[1.28, 0.17, -1.37]}
          userData={{
            name: 'Tulip.100',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip101"
          geometry={nodes.Tulip101.geometry}
          material={nodes.Tulip101.material}
          position={[1.27, 0.17, -1.08]}
          userData={{
            name: 'Tulip.101',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip102"
          geometry={nodes.Tulip102.geometry}
          material={nodes.Tulip102.material}
          position={[1.09, 0.17, -2.37]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.102',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip103"
          geometry={nodes.Tulip103.geometry}
          material={nodes.Tulip103.material}
          position={[1.06, 0.17, -1.99]}
          userData={{
            name: 'Tulip.103',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip104"
          geometry={nodes.Tulip104.geometry}
          material={nodes.Tulip104.material}
          position={[1.03, 0.17, -1.63]}
          userData={{
            name: 'Tulip.104',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip105"
          geometry={nodes.Tulip105.geometry}
          material={nodes.Tulip105.material}
          position={[1.02, 0.17, -1.33]}
          userData={{
            name: 'Tulip.105',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip106"
          geometry={nodes.Tulip106.geometry}
          material={nodes.Tulip106.material}
          position={[0.93, 0.17, -2.17]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.106',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip107"
          geometry={nodes.Tulip107.geometry}
          material={nodes.Tulip107.material}
          position={[0.9, 0.17, -1.7]}
          userData={{
            name: 'Tulip.107',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip108"
          geometry={nodes.Tulip108.geometry}
          material={nodes.Tulip108.material}
          position={[0.86, 0.17, -1.34]}
          userData={{
            name: 'Tulip.108',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip109"
          geometry={nodes.Tulip109.geometry}
          material={nodes.Tulip109.material}
          position={[0.56, 0.17, -2.13]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.109',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip110"
          geometry={nodes.Tulip110.geometry}
          material={nodes.Tulip110.material}
          position={[0.73, 0.17, -1.66]}
          userData={{
            name: 'Tulip.110',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip111"
          geometry={nodes.Tulip111.geometry}
          material={nodes.Tulip111.material}
          position={[0.7, 0.17, -1.3]}
          userData={{
            name: 'Tulip.111',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip112"
          geometry={nodes.Tulip112.geometry}
          material={nodes.Tulip112.material}
          position={[0.14, 0.17, -0.71]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.112',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip113"
          geometry={nodes.Tulip113.geometry}
          material={nodes.Tulip113.material}
          position={[-0.23, 0.17, -0.76]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.113',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip114"
          geometry={nodes.Tulip114.geometry}
          material={nodes.Tulip114.material}
          position={[-0.72, 0.17, -0.83]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.114',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip115"
          geometry={nodes.Tulip115.geometry}
          material={nodes.Tulip115.material}
          position={[-0.95, 0.17, -0.36]}
          userData={{
            name: 'Tulip.115',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip116"
          geometry={nodes.Tulip116.geometry}
          material={nodes.Tulip116.material}
          position={[-0.36, 0.17, -0.34]}
          userData={{
            name: 'Tulip.116',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip117"
          geometry={nodes.Tulip117.geometry}
          material={nodes.Tulip117.material}
          position={[0.27, 0.17, -0.32]}
          userData={{
            name: 'Tulip.117',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip118"
          geometry={nodes.Tulip118.geometry}
          material={nodes.Tulip118.material}
          position={[-0.78, 0.17, 0]}
          userData={{
            name: 'Tulip.118',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip119"
          geometry={nodes.Tulip119.geometry}
          material={nodes.Tulip119.material}
          position={[-0.4, 0.17, 0.02]}
          userData={{
            name: 'Tulip.119',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip120"
          geometry={nodes.Tulip120.geometry}
          material={nodes.Tulip120.material}
          position={[0.09, 0.17, 0.04]}
          userData={{
            name: 'Tulip.120',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip121"
          geometry={nodes.Tulip121.geometry}
          material={nodes.Tulip121.material}
          position={[0.08, 0.17, 0.33]}
          userData={{
            name: 'Tulip.121',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip122"
          geometry={nodes.Tulip122.geometry}
          material={nodes.Tulip122.material}
          position={[-0.04, 0.17, -0.84]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.122',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip123"
          geometry={nodes.Tulip123.geometry}
          material={nodes.Tulip123.material}
          position={[-0.07, 0.17, -0.45]}
          userData={{
            name: 'Tulip.123',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip124"
          geometry={nodes.Tulip124.geometry}
          material={nodes.Tulip124.material}
          position={[-0.1, 0.17, -0.09]}
          userData={{
            name: 'Tulip.124',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip125"
          geometry={nodes.Tulip125.geometry}
          material={nodes.Tulip125.material}
          position={[-0.11, 0.17, 0.2]}
          userData={{
            name: 'Tulip.125',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip126"
          geometry={nodes.Tulip126.geometry}
          material={nodes.Tulip126.material}
          position={[-0.19, 0.17, -0.64]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.126',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip127"
          geometry={nodes.Tulip127.geometry}
          material={nodes.Tulip127.material}
          position={[-0.21, 0.17, -0.25]}
          userData={{
            name: 'Tulip.127',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip128"
          geometry={nodes.Tulip128.geometry}
          material={nodes.Tulip128.material}
          position={[-0.25, 0.17, 0.11]}
          userData={{
            name: 'Tulip.128',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip129"
          geometry={nodes.Tulip129.geometry}
          material={nodes.Tulip129.material}
          position={[-0.26, 0.17, 0.4]}
          userData={{
            name: 'Tulip.129',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip130"
          geometry={nodes.Tulip130.geometry}
          material={nodes.Tulip130.material}
          position={[-0.44, 0.17, -0.9]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.130',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip131"
          geometry={nodes.Tulip131.geometry}
          material={nodes.Tulip131.material}
          position={[-0.47, 0.17, -0.51]}
          userData={{
            name: 'Tulip.131',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip132"
          geometry={nodes.Tulip132.geometry}
          material={nodes.Tulip132.material}
          position={[-0.5, 0.17, -0.15]}
          userData={{
            name: 'Tulip.132',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip133"
          geometry={nodes.Tulip133.geometry}
          material={nodes.Tulip133.material}
          position={[-0.51, 0.17, 0.15]}
          userData={{
            name: 'Tulip.133',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip134"
          geometry={nodes.Tulip134.geometry}
          material={nodes.Tulip134.material}
          position={[-0.6, 0.17, -0.69]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.134',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip135"
          geometry={nodes.Tulip135.geometry}
          material={nodes.Tulip135.material}
          position={[-0.63, 0.17, -0.22]}
          userData={{
            name: 'Tulip.135',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip136"
          geometry={nodes.Tulip136.geometry}
          material={nodes.Tulip136.material}
          position={[-0.67, 0.17, 0.14]}
          userData={{
            name: 'Tulip.136',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip137"
          geometry={nodes.Tulip137.geometry}
          material={nodes.Tulip137.material}
          position={[-0.97, 0.17, -0.65]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.137',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip138"
          geometry={nodes.Tulip138.geometry}
          material={nodes.Tulip138.material}
          position={[-0.8, 0.17, -0.18]}
          userData={{
            name: 'Tulip.138',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip139"
          geometry={nodes.Tulip139.geometry}
          material={nodes.Tulip139.material}
          position={[-0.83, 0.17, 0.18]}
          userData={{
            name: 'Tulip.139',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip140"
          geometry={nodes.Tulip140.geometry}
          material={nodes.Tulip140.material}
          position={[1.59, 0.17, -0.78]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.140',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip141"
          geometry={nodes.Tulip141.geometry}
          material={nodes.Tulip141.material}
          position={[1.21, 0.17, -0.83]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.141',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip142"
          geometry={nodes.Tulip142.geometry}
          material={nodes.Tulip142.material}
          position={[0.73, 0.17, -0.89]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.142',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip143"
          geometry={nodes.Tulip143.geometry}
          material={nodes.Tulip143.material}
          position={[0.7, 0.17, -0.43]}
          userData={{
            name: 'Tulip.143',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip144"
          geometry={nodes.Tulip144.geometry}
          material={nodes.Tulip144.material}
          position={[1.08, 0.17, -0.41]}
          userData={{
            name: 'Tulip.144',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip145"
          geometry={nodes.Tulip145.geometry}
          material={nodes.Tulip145.material}
          position={[1.56, 0.17, -0.39]}
          userData={{
            name: 'Tulip.145',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip146"
          geometry={nodes.Tulip146.geometry}
          material={nodes.Tulip146.material}
          position={[0.67, 0.17, -0.07]}
          userData={{
            name: 'Tulip.146',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip147"
          geometry={nodes.Tulip147.geometry}
          material={nodes.Tulip147.material}
          position={[1.05, 0.17, -0.05]}
          userData={{
            name: 'Tulip.147',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip148"
          geometry={nodes.Tulip148.geometry}
          material={nodes.Tulip148.material}
          position={[1.53, 0.17, -0.03]}
          userData={{
            name: 'Tulip.148',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip149"
          geometry={nodes.Tulip149.geometry}
          material={nodes.Tulip149.material}
          position={[1.52, 0.17, 0.27]}
          userData={{
            name: 'Tulip.149',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip150"
          geometry={nodes.Tulip150.geometry}
          material={nodes.Tulip150.material}
          position={[1.4, 0.17, -0.91]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.150',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip151"
          geometry={nodes.Tulip151.geometry}
          material={nodes.Tulip151.material}
          position={[1.38, 0.17, -0.52]}
          userData={{
            name: 'Tulip.151',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip152"
          geometry={nodes.Tulip152.geometry}
          material={nodes.Tulip152.material}
          position={[1.35, 0.17, -0.16]}
          userData={{
            name: 'Tulip.152',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip153"
          geometry={nodes.Tulip153.geometry}
          material={nodes.Tulip153.material}
          position={[1.34, 0.17, 0.13]}
          userData={{
            name: 'Tulip.153',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip154"
          geometry={nodes.Tulip154.geometry}
          material={nodes.Tulip154.material}
          position={[1.25, 0.17, -0.71]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.154',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip155"
          geometry={nodes.Tulip155.geometry}
          material={nodes.Tulip155.material}
          position={[1.23, 0.17, -0.32]}
          userData={{
            name: 'Tulip.155',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip156"
          geometry={nodes.Tulip156.geometry}
          material={nodes.Tulip156.material}
          position={[1.2, 0.17, 0.04]}
          userData={{
            name: 'Tulip.156',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip157"
          geometry={nodes.Tulip157.geometry}
          material={nodes.Tulip157.material}
          position={[1.19, 0.17, 0.33]}
          userData={{
            name: 'Tulip.157',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip158"
          geometry={nodes.Tulip158.geometry}
          material={nodes.Tulip158.material}
          position={[1, 0.17, -0.96]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.158',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip159"
          geometry={nodes.Tulip159.geometry}
          material={nodes.Tulip159.material}
          position={[0.98, 0.17, -0.58]}
          userData={{
            name: 'Tulip.159',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip160"
          geometry={nodes.Tulip160.geometry}
          material={nodes.Tulip160.material}
          position={[0.95, 0.17, -0.22]}
          userData={{
            name: 'Tulip.160',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip161"
          geometry={nodes.Tulip161.geometry}
          material={nodes.Tulip161.material}
          position={[0.94, 0.17, 0.08]}
          userData={{
            name: 'Tulip.161',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip162"
          geometry={nodes.Tulip162.geometry}
          material={nodes.Tulip162.material}
          position={[0.84, 0.17, -0.75]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.162',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip163"
          geometry={nodes.Tulip163.geometry}
          material={nodes.Tulip163.material}
          position={[0.81, 0.17, -0.29]}
          userData={{
            name: 'Tulip.163',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip164"
          geometry={nodes.Tulip164.geometry}
          material={nodes.Tulip164.material}
          position={[0.78, 0.17, 0.07]}
          userData={{
            name: 'Tulip.164',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip165"
          geometry={nodes.Tulip165.geometry}
          material={nodes.Tulip165.material}
          position={[0.67, 0.17, -0.71]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.165',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip166"
          geometry={nodes.Tulip166.geometry}
          material={nodes.Tulip166.material}
          position={[0.64, 0.17, -0.25]}
          userData={{
            name: 'Tulip.166',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip167"
          geometry={nodes.Tulip167.geometry}
          material={nodes.Tulip167.material}
          position={[0.61, 0.17, 0.11]}
          userData={{
            name: 'Tulip.167',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip168"
          geometry={nodes.Tulip168.geometry}
          material={nodes.Tulip168.material}
          position={[0.85, 0.17, 0.72]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.168',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip169"
          geometry={nodes.Tulip169.geometry}
          material={nodes.Tulip169.material}
          position={[0.48, 0.17, 0.66]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.169',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip170"
          geometry={nodes.Tulip170.geometry}
          material={nodes.Tulip170.material}
          position={[0, 0.17, 0.6]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.170',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip171"
          geometry={nodes.Tulip171.geometry}
          material={nodes.Tulip171.material}
          position={[-0.04, 0.17, 1.06]}
          userData={{
            name: 'Tulip.171',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip172"
          geometry={nodes.Tulip172.geometry}
          material={nodes.Tulip172.material}
          position={[0.35, 0.17, 1.08]}
          userData={{
            name: 'Tulip.172',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip173"
          geometry={nodes.Tulip173.geometry}
          material={nodes.Tulip173.material}
          position={[1.03, 0.17, 1.11]}
          userData={{
            name: 'Tulip.173',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip174"
          geometry={nodes.Tulip174.geometry}
          material={nodes.Tulip174.material}
          position={[-0.07, 0.17, 1.42]}
          userData={{
            name: 'Tulip.174',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip175"
          geometry={nodes.Tulip175.geometry}
          material={nodes.Tulip175.material}
          position={[0.31, 0.17, 1.44]}
          userData={{
            name: 'Tulip.175',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip176"
          geometry={nodes.Tulip176.geometry}
          material={nodes.Tulip176.material}
          position={[0.8, 0.17, 1.46]}
          userData={{
            name: 'Tulip.176',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip177"
          geometry={nodes.Tulip177.geometry}
          material={nodes.Tulip177.material}
          position={[0.79, 0.17, 1.76]}
          userData={{
            name: 'Tulip.177',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip178"
          geometry={nodes.Tulip178.geometry}
          material={nodes.Tulip178.material}
          position={[0.67, 0.17, 0.58]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.178',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip179"
          geometry={nodes.Tulip179.geometry}
          material={nodes.Tulip179.material}
          position={[0.64, 0.17, 0.97]}
          userData={{
            name: 'Tulip.179',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip180"
          geometry={nodes.Tulip180.geometry}
          material={nodes.Tulip180.material}
          position={[0.61, 0.17, 1.33]}
          userData={{
            name: 'Tulip.180',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip181"
          geometry={nodes.Tulip181.geometry}
          material={nodes.Tulip181.material}
          position={[0.6, 0.17, 1.63]}
          userData={{
            name: 'Tulip.181',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip182"
          geometry={nodes.Tulip182.geometry}
          material={nodes.Tulip182.material}
          position={[0.52, 0.17, 0.78]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.182',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip183"
          geometry={nodes.Tulip183.geometry}
          material={nodes.Tulip183.material}
          position={[0.5, 0.17, 1.17]}
          userData={{
            name: 'Tulip.183',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip184"
          geometry={nodes.Tulip184.geometry}
          material={nodes.Tulip184.material}
          position={[0.46, 0.17, 1.53]}
          userData={{
            name: 'Tulip.184',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip185"
          geometry={nodes.Tulip185.geometry}
          material={nodes.Tulip185.material}
          position={[0.45, 0.17, 1.83]}
          userData={{
            name: 'Tulip.185',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip186"
          geometry={nodes.Tulip186.geometry}
          material={nodes.Tulip186.material}
          position={[0.35, 0.14, 0.17]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.186',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip187"
          geometry={nodes.Tulip187.geometry}
          material={nodes.Tulip187.material}
          position={[0.24, 0.17, 0.92]}
          userData={{
            name: 'Tulip.187',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip188"
          geometry={nodes.Tulip188.geometry}
          material={nodes.Tulip188.material}
          position={[0.21, 0.17, 1.28]}
          userData={{
            name: 'Tulip.188',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip189"
          geometry={nodes.Tulip189.geometry}
          material={nodes.Tulip189.material}
          position={[0.2, 0.17, 1.57]}
          userData={{
            name: 'Tulip.189',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip190"
          geometry={nodes.Tulip190.geometry}
          material={nodes.Tulip190.material}
          position={[0.11, 0.17, 0.74]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.190',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip191"
          geometry={nodes.Tulip191.geometry}
          material={nodes.Tulip191.material}
          position={[0.08, 0.17, 1.2]}
          userData={{
            name: 'Tulip.191',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip192"
          geometry={nodes.Tulip192.geometry}
          material={nodes.Tulip192.material}
          position={[0.04, 0.17, 1.56]}
          userData={{
            name: 'Tulip.192',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip193"
          geometry={nodes.Tulip193.geometry}
          material={nodes.Tulip193.material}
          position={[-0.06, 0.17, 0.78]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.193',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip194"
          geometry={nodes.Tulip194.geometry}
          material={nodes.Tulip194.material}
          position={[-0.09, 0.17, 1.24]}
          userData={{
            name: 'Tulip.194',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip195"
          geometry={nodes.Tulip195.geometry}
          material={nodes.Tulip195.material}
          position={[-0.12, 0.17, 1.6]}
          userData={{
            name: 'Tulip.195',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip196"
          geometry={nodes.Tulip196.geometry}
          material={nodes.Tulip196.material}
          position={[-0.22, 0.17, 0.55]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.196',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip197"
          geometry={nodes.Tulip197.geometry}
          material={nodes.Tulip197.material}
          position={[-0.6, 0.17, 0.49]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.197',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip198"
          geometry={nodes.Tulip198.geometry}
          material={nodes.Tulip198.material}
          position={[-1.08, 0.17, 0.43]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.198',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip199"
          geometry={nodes.Tulip199.geometry}
          material={nodes.Tulip199.material}
          position={[-1.11, 0.17, 0.89]}
          userData={{
            name: 'Tulip.199',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip200"
          geometry={nodes.Tulip200.geometry}
          material={nodes.Tulip200.material}
          position={[-0.73, 0.17, 0.91]}
          userData={{
            name: 'Tulip.200',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip201"
          geometry={nodes.Tulip201.geometry}
          material={nodes.Tulip201.material}
          position={[-0.25, 0.17, 0.93]}
          userData={{
            name: 'Tulip.201',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip202"
          geometry={nodes.Tulip202.geometry}
          material={nodes.Tulip202.material}
          position={[-1.14, 0.17, 1.25]}
          userData={{
            name: 'Tulip.202',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip203"
          geometry={nodes.Tulip203.geometry}
          material={nodes.Tulip203.material}
          position={[-0.76, 0.17, 1.27]}
          userData={{
            name: 'Tulip.203',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip204"
          geometry={nodes.Tulip204.geometry}
          material={nodes.Tulip204.material}
          position={[-0.28, 0.17, 1.29]}
          userData={{
            name: 'Tulip.204',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip205"
          geometry={nodes.Tulip205.geometry}
          material={nodes.Tulip205.material}
          position={[0.19, 0.17, 2.01]}
          userData={{
            name: 'Tulip.205',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip206"
          geometry={nodes.Tulip206.geometry}
          material={nodes.Tulip206.material}
          position={[-0.41, 0.17, 0.41]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.206',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip207"
          geometry={nodes.Tulip207.geometry}
          material={nodes.Tulip207.material}
          position={[-0.43, 0.17, 0.8]}
          userData={{
            name: 'Tulip.207',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip208"
          geometry={nodes.Tulip208.geometry}
          material={nodes.Tulip208.material}
          position={[-0.47, 0.17, 1.16]}
          userData={{
            name: 'Tulip.208',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip209"
          geometry={nodes.Tulip209.geometry}
          material={nodes.Tulip209.material}
          position={[-0.47, 0.17, 1.46]}
          userData={{
            name: 'Tulip.209',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip210"
          geometry={nodes.Tulip210.geometry}
          material={nodes.Tulip210.material}
          position={[-0.56, 0.17, 0.61]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.210',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip211"
          geometry={nodes.Tulip211.geometry}
          material={nodes.Tulip211.material}
          position={[-0.58, 0.17, 1]}
          userData={{
            name: 'Tulip.211',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip212"
          geometry={nodes.Tulip212.geometry}
          material={nodes.Tulip212.material}
          position={[-0.61, 0.17, 1.36]}
          userData={{
            name: 'Tulip.212',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip213"
          geometry={nodes.Tulip213.geometry}
          material={nodes.Tulip213.material}
          position={[-0.62, 0.17, 1.66]}
          userData={{
            name: 'Tulip.213',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip214"
          geometry={nodes.Tulip214.geometry}
          material={nodes.Tulip214.material}
          position={[-0.81, 0.17, 0.36]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.214',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip215"
          geometry={nodes.Tulip215.geometry}
          material={nodes.Tulip215.material}
          position={[-0.83, 0.17, 0.75]}
          userData={{
            name: 'Tulip.215',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip216"
          geometry={nodes.Tulip216.geometry}
          material={nodes.Tulip216.material}
          position={[-0.86, 0.17, 1.1]}
          userData={{
            name: 'Tulip.216',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip217"
          geometry={nodes.Tulip217.geometry}
          material={nodes.Tulip217.material}
          position={[-0.87, 0.17, 1.4]}
          userData={{
            name: 'Tulip.217',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip218"
          geometry={nodes.Tulip218.geometry}
          material={nodes.Tulip218.material}
          position={[-0.97, 0.17, 0.57]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.218',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip219"
          geometry={nodes.Tulip219.geometry}
          material={nodes.Tulip219.material}
          position={[-1, 0.17, 1.03]}
          userData={{
            name: 'Tulip.219',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip220"
          geometry={nodes.Tulip220.geometry}
          material={nodes.Tulip220.material}
          position={[-1.03, 0.17, 1.39]}
          userData={{
            name: 'Tulip.220',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip221"
          geometry={nodes.Tulip221.geometry}
          material={nodes.Tulip221.material}
          position={[-1.14, 0.17, 0.61]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.221',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip222"
          geometry={nodes.Tulip222.geometry}
          material={nodes.Tulip222.material}
          position={[-0.5, 0.17, 1.99]}
          userData={{
            name: 'Tulip.222',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip223"
          geometry={nodes.Tulip223.geometry}
          material={nodes.Tulip223.material}
          position={[-0.8, 0.17, 1.83]}
          userData={{
            name: 'Tulip.223',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip224"
          geometry={nodes.Tulip224.geometry}
          material={nodes.Tulip224.material}
          position={[3.65, 0.17, -6.75]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.224',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip225"
          geometry={nodes.Tulip225.geometry}
          material={nodes.Tulip225.material}
          position={[3.28, 0.17, -6.8]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.225',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip226"
          geometry={nodes.Tulip226.geometry}
          material={nodes.Tulip226.material}
          position={[2.8, 0.17, -6.87]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.226',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip227"
          geometry={nodes.Tulip227.geometry}
          material={nodes.Tulip227.material}
          position={[2.76, 0.17, -6.41]}
          userData={{
            name: 'Tulip.227',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip228"
          geometry={nodes.Tulip228.geometry}
          material={nodes.Tulip228.material}
          position={[3.15, 0.17, -6.39]}
          userData={{
            name: 'Tulip.228',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip229"
          geometry={nodes.Tulip229.geometry}
          material={nodes.Tulip229.material}
          position={[3.63, 0.17, -6.36]}
          userData={{
            name: 'Tulip.229',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip230"
          geometry={nodes.Tulip230.geometry}
          material={nodes.Tulip230.material}
          position={[2.73, 0.17, -6.05]}
          userData={{
            name: 'Tulip.230',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip231"
          geometry={nodes.Tulip231.geometry}
          material={nodes.Tulip231.material}
          position={[3.11, 0.17, -6.03]}
          userData={{
            name: 'Tulip.231',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip232"
          geometry={nodes.Tulip232.geometry}
          material={nodes.Tulip232.material}
          position={[3.6, 0.17, -6]}
          userData={{
            name: 'Tulip.232',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip233"
          geometry={nodes.Tulip233.geometry}
          material={nodes.Tulip233.material}
          position={[3.59, 0.17, -5.71]}
          userData={{
            name: 'Tulip.233',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip234"
          geometry={nodes.Tulip234.geometry}
          material={nodes.Tulip234.material}
          position={[3.47, 0.17, -6.88]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.234',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip235"
          geometry={nodes.Tulip235.geometry}
          material={nodes.Tulip235.material}
          position={[3.44, 0.17, -6.49]}
          userData={{
            name: 'Tulip.235',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip236"
          geometry={nodes.Tulip236.geometry}
          material={nodes.Tulip236.material}
          position={[3.41, 0.17, -6.14]}
          userData={{
            name: 'Tulip.236',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip237"
          geometry={nodes.Tulip237.geometry}
          material={nodes.Tulip237.material}
          position={[3.4, 0.17, -5.84]}
          userData={{
            name: 'Tulip.237',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip238"
          geometry={nodes.Tulip238.geometry}
          material={nodes.Tulip238.material}
          position={[3.32, 0.17, -6.68]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.238',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip239"
          geometry={nodes.Tulip239.geometry}
          material={nodes.Tulip239.material}
          position={[3.3, 0.17, -6.3]}
          userData={{
            name: 'Tulip.239',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip240"
          geometry={nodes.Tulip240.geometry}
          material={nodes.Tulip240.material}
          position={[3.26, 0.17, -5.94]}
          userData={{
            name: 'Tulip.240',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip241"
          geometry={nodes.Tulip241.geometry}
          material={nodes.Tulip241.material}
          position={[3.25, 0.17, -5.64]}
          userData={{
            name: 'Tulip.241',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip242"
          geometry={nodes.Tulip242.geometry}
          material={nodes.Tulip242.material}
          position={[3.07, 0.17, -6.94]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.242',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip243"
          geometry={nodes.Tulip243.geometry}
          material={nodes.Tulip243.material}
          position={[3.04, 0.17, -6.55]}
          userData={{
            name: 'Tulip.243',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip244"
          geometry={nodes.Tulip244.geometry}
          material={nodes.Tulip244.material}
          position={[3.01, 0.17, -6.19]}
          userData={{
            name: 'Tulip.244',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip245"
          geometry={nodes.Tulip245.geometry}
          material={nodes.Tulip245.material}
          position={[3, 0.17, -5.9]}
          userData={{
            name: 'Tulip.245',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip246"
          geometry={nodes.Tulip246.geometry}
          material={nodes.Tulip246.material}
          position={[2.91, 0.17, -6.73]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.246',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip247"
          geometry={nodes.Tulip247.geometry}
          material={nodes.Tulip247.material}
          position={[2.88, 0.17, -6.27]}
          userData={{
            name: 'Tulip.247',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip248"
          geometry={nodes.Tulip248.geometry}
          material={nodes.Tulip248.material}
          position={[2.84, 0.17, -5.91]}
          userData={{
            name: 'Tulip.248',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip249"
          geometry={nodes.Tulip249.geometry}
          material={nodes.Tulip249.material}
          position={[2.74, 0.17, -6.69]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.249',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip250"
          geometry={nodes.Tulip250.geometry}
          material={nodes.Tulip250.material}
          position={[2.71, 0.17, -6.23]}
          userData={{
            name: 'Tulip.250',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip251"
          geometry={nodes.Tulip251.geometry}
          material={nodes.Tulip251.material}
          position={[2.68, 0.17, -5.87]}
          userData={{
            name: 'Tulip.251',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip252"
          geometry={nodes.Tulip252.geometry}
          material={nodes.Tulip252.material}
          position={[2.43, 0.17, -6.68]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.252',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip253"
          geometry={nodes.Tulip253.geometry}
          material={nodes.Tulip253.material}
          position={[2.49, 0.17, -7.06]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.253',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip256"
          geometry={nodes.Tulip256.geometry}
          material={nodes.Tulip256.material}
          position={[2.07, 0.17, -7.19]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.256',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip257"
          geometry={nodes.Tulip257.geometry}
          material={nodes.Tulip257.material}
          position={[2.05, 0.17, -6.7]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.257',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip259"
          geometry={nodes.Tulip259.geometry}
          material={nodes.Tulip259.material}
          position={[1.71, 0.17, -7.22]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.259',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip260"
          geometry={nodes.Tulip260.geometry}
          material={nodes.Tulip260.material}
          position={[1.69, 0.17, -6.73]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.260',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip261"
          geometry={nodes.Tulip261.geometry}
          material={nodes.Tulip261.material}
          position={[1.39, 0.17, -6.74]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.261',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip262"
          geometry={nodes.Tulip262.geometry}
          material={nodes.Tulip262.material}
          position={[2.57, 0.17, -6.87]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.262',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip263"
          geometry={nodes.Tulip263.geometry}
          material={nodes.Tulip263.material}
          position={[2.18, 0.17, -6.89]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.263',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip264"
          geometry={nodes.Tulip264.geometry}
          material={nodes.Tulip264.material}
          position={[1.82, 0.17, -6.92]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.264',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip265"
          geometry={nodes.Tulip265.geometry}
          material={nodes.Tulip265.material}
          position={[1.52, 0.17, -6.93]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.265',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip266"
          geometry={nodes.Tulip266.geometry}
          material={nodes.Tulip266.material}
          position={[2.37, 0.17, -7.01]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.266',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip267"
          geometry={nodes.Tulip267.geometry}
          material={nodes.Tulip267.material}
          position={[1.98, 0.17, -7.04]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.267',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip268"
          geometry={nodes.Tulip268.geometry}
          material={nodes.Tulip268.material}
          position={[1.62, 0.17, -7.07]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.268',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip269"
          geometry={nodes.Tulip269.geometry}
          material={nodes.Tulip269.material}
          position={[1.32, 0.17, -7.08]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.269',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip270"
          geometry={nodes.Tulip270.geometry}
          material={nodes.Tulip270.material}
          position={[2.62, 0.17, -7.27]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.270',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip271"
          geometry={nodes.Tulip271.geometry}
          material={nodes.Tulip271.material}
          position={[2.23, 0.17, -7.29]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.271',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip272"
          geometry={nodes.Tulip272.geometry}
          material={nodes.Tulip272.material}
          position={[1.88, 0.17, -7.32]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.272',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip273"
          geometry={nodes.Tulip273.geometry}
          material={nodes.Tulip273.material}
          position={[1.58, 0.17, -7.33]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.273',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip274"
          geometry={nodes.Tulip274.geometry}
          material={nodes.Tulip274.material}
          position={[2.41, 0.17, -7.43]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.274',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip275"
          geometry={nodes.Tulip275.geometry}
          material={nodes.Tulip275.material}
          position={[1.95, 0.17, -7.46]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.275',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip276"
          geometry={nodes.Tulip276.geometry}
          material={nodes.Tulip276.material}
          position={[1.59, 0.17, -7.49]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.276',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip280"
          geometry={nodes.Tulip280.geometry}
          material={nodes.Tulip280.material}
          position={[0.22, 0.17, -4.03]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.280',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip281"
          geometry={nodes.Tulip281.geometry}
          material={nodes.Tulip281.material}
          position={[0.28, 0.17, -4.4]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.281',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip282"
          geometry={nodes.Tulip282.geometry}
          material={nodes.Tulip282.material}
          position={[0.34, 0.17, -4.89]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.282',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip283"
          geometry={nodes.Tulip283.geometry}
          material={nodes.Tulip283.material}
          position={[-0.12, 0.17, -4.92]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.283',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip284"
          geometry={nodes.Tulip284.geometry}
          material={nodes.Tulip284.material}
          position={[-0.14, 0.17, -4.54]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.284',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip285"
          geometry={nodes.Tulip285.geometry}
          material={nodes.Tulip285.material}
          position={[-0.16, 0.17, -4.05]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.285',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip286"
          geometry={nodes.Tulip286.geometry}
          material={nodes.Tulip286.material}
          position={[-0.48, 0.17, -4.95]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.286',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip287"
          geometry={nodes.Tulip287.geometry}
          material={nodes.Tulip287.material}
          position={[-0.5, 0.17, -4.57]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.287',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip288"
          geometry={nodes.Tulip288.geometry}
          material={nodes.Tulip288.material}
          position={[-0.52, 0.17, -4.08]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.288',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip289"
          geometry={nodes.Tulip289.geometry}
          material={nodes.Tulip289.material}
          position={[-0.82, 0.17, -4.09]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.289',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip290"
          geometry={nodes.Tulip290.geometry}
          material={nodes.Tulip290.material}
          position={[0.36, 0.17, -4.21]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.290',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip291"
          geometry={nodes.Tulip291.geometry}
          material={nodes.Tulip291.material}
          position={[-0.03, 0.17, -4.24]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.291',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip292"
          geometry={nodes.Tulip292.geometry}
          material={nodes.Tulip292.material}
          position={[-0.39, 0.17, -4.27]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.292',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip293"
          geometry={nodes.Tulip293.geometry}
          material={nodes.Tulip293.material}
          position={[-0.69, 0.17, -4.28]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.293',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip294"
          geometry={nodes.Tulip294.geometry}
          material={nodes.Tulip294.material}
          position={[0.16, 0.17, -4.36]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.294',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip295"
          geometry={nodes.Tulip295.geometry}
          material={nodes.Tulip295.material}
          position={[-0.23, 0.17, -4.39]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.295',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip296"
          geometry={nodes.Tulip296.geometry}
          material={nodes.Tulip296.material}
          position={[-0.59, 0.17, -4.42]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.296',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip297"
          geometry={nodes.Tulip297.geometry}
          material={nodes.Tulip297.material}
          position={[-0.89, 0.17, -4.43]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.297',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip298"
          geometry={nodes.Tulip298.geometry}
          material={nodes.Tulip298.material}
          position={[0.41, 0.17, -4.61]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.298',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip299"
          geometry={nodes.Tulip299.geometry}
          material={nodes.Tulip299.material}
          position={[0.02, 0.17, -4.64]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.299',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip300"
          geometry={nodes.Tulip300.geometry}
          material={nodes.Tulip300.material}
          position={[-0.33, 0.17, -4.67]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.300',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip301"
          geometry={nodes.Tulip301.geometry}
          material={nodes.Tulip301.material}
          position={[-0.63, 0.17, -4.68]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.301',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip302"
          geometry={nodes.Tulip302.geometry}
          material={nodes.Tulip302.material}
          position={[0.2, 0.17, -4.77]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.302',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip303"
          geometry={nodes.Tulip303.geometry}
          material={nodes.Tulip303.material}
          position={[-0.26, 0.17, -4.8]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.303',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip304"
          geometry={nodes.Tulip304.geometry}
          material={nodes.Tulip304.material}
          position={[-0.62, 0.17, -4.84]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.304',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip305"
          geometry={nodes.Tulip305.geometry}
          material={nodes.Tulip305.material}
          position={[0.16, 0.17, -4.94]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.305',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip306"
          geometry={nodes.Tulip306.geometry}
          material={nodes.Tulip306.material}
          position={[-0.3, 0.17, -4.97]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.306',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip307"
          geometry={nodes.Tulip307.geometry}
          material={nodes.Tulip307.material}
          position={[-0.66, 0.17, -5]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.307',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip308"
          geometry={nodes.Tulip308.geometry}
          material={nodes.Tulip308.material}
          position={[2.42, 0.17, -3.99]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.308',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip309"
          geometry={nodes.Tulip309.geometry}
          material={nodes.Tulip309.material}
          position={[2.47, 0.17, -4.37]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.309',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip310"
          geometry={nodes.Tulip310.geometry}
          material={nodes.Tulip310.material}
          position={[2.54, 0.17, -4.85]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.310',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip311"
          geometry={nodes.Tulip311.geometry}
          material={nodes.Tulip311.material}
          position={[2.07, 0.17, -4.88]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.311',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip312"
          geometry={nodes.Tulip312.geometry}
          material={nodes.Tulip312.material}
          position={[2.05, 0.17, -4.5]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.312',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip313"
          geometry={nodes.Tulip313.geometry}
          material={nodes.Tulip313.material}
          position={[2.03, 0.17, -4.02]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.313',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip314"
          geometry={nodes.Tulip314.geometry}
          material={nodes.Tulip314.material}
          position={[1.71, 0.17, -4.91]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.314',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip315"
          geometry={nodes.Tulip315.geometry}
          material={nodes.Tulip315.material}
          position={[1.69, 0.17, -4.53]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.315',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip316"
          geometry={nodes.Tulip316.geometry}
          material={nodes.Tulip316.material}
          position={[1.67, 0.17, -4.05]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.316',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip317"
          geometry={nodes.Tulip317.geometry}
          material={nodes.Tulip317.material}
          position={[1.37, 0.17, -4.06]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.317',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip318"
          geometry={nodes.Tulip318.geometry}
          material={nodes.Tulip318.material}
          position={[2.55, 0.17, -4.18]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.318',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip319"
          geometry={nodes.Tulip319.geometry}
          material={nodes.Tulip319.material}
          position={[2.16, 0.17, -4.2]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.319',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip320"
          geometry={nodes.Tulip320.geometry}
          material={nodes.Tulip320.material}
          position={[1.8, 0.17, -4.23]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.320',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip321"
          geometry={nodes.Tulip321.geometry}
          material={nodes.Tulip321.material}
          position={[1.51, 0.17, -4.24]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.321',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip322"
          geometry={nodes.Tulip322.geometry}
          material={nodes.Tulip322.material}
          position={[2.35, 0.17, -4.33]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.322',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip323"
          geometry={nodes.Tulip323.geometry}
          material={nodes.Tulip323.material}
          position={[1.96, 0.17, -4.35]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.323',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip324"
          geometry={nodes.Tulip324.geometry}
          material={nodes.Tulip324.material}
          position={[1.6, 0.17, -4.38]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.324',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip325"
          geometry={nodes.Tulip325.geometry}
          material={nodes.Tulip325.material}
          position={[1.31, 0.17, -4.39]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.325',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip326"
          geometry={nodes.Tulip326.geometry}
          material={nodes.Tulip326.material}
          position={[2.6, 0.17, -4.58]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.326',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip327"
          geometry={nodes.Tulip327.geometry}
          material={nodes.Tulip327.material}
          position={[2.22, 0.17, -4.6]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.327',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip328"
          geometry={nodes.Tulip328.geometry}
          material={nodes.Tulip328.material}
          position={[1.86, 0.17, -4.63]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.328',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip329"
          geometry={nodes.Tulip329.geometry}
          material={nodes.Tulip329.material}
          position={[1.56, 0.17, -4.64]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.329',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip330"
          geometry={nodes.Tulip330.geometry}
          material={nodes.Tulip330.material}
          position={[2.4, 0.17, -4.74]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.330',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip331"
          geometry={nodes.Tulip331.geometry}
          material={nodes.Tulip331.material}
          position={[1.93, 0.17, -4.77]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.331',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip332"
          geometry={nodes.Tulip332.geometry}
          material={nodes.Tulip332.material}
          position={[1.57, 0.17, -4.8]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.332',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip333"
          geometry={nodes.Tulip333.geometry}
          material={nodes.Tulip333.material}
          position={[2.36, 0.17, -4.91]}
          rotation={[0, 1.48, 0]}
          userData={{
            name: 'Tulip.333',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip334"
          geometry={nodes.Tulip334.geometry}
          material={nodes.Tulip334.material}
          position={[1.89, 0.17, -4.94]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.334',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip335"
          geometry={nodes.Tulip335.geometry}
          material={nodes.Tulip335.material}
          position={[1.53, 0.17, -4.97]}
          rotation={[0, -1.57, 0]}
          userData={{
            name: 'Tulip.335',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip032"
          geometry={nodes.Tulip032.geometry}
          material={nodes.Tulip032.material}
          position={[-0.36, 0.17, 2.48]}
          userData={{
            name: 'Tulip.032',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Tulip033"
          geometry={nodes.Tulip033.geometry}
          material={nodes.Tulip033.material}
          position={[-0.19, 0.17, 2.18]}
          rotation={[-Math.PI, 0.09, -Math.PI]}
          userData={{
            name: 'Tulip.033',
            'mr displacement use global settings': 1,
            'mr displacement view dependent': 1,
            'mr displacement method': 6,
            'mr displacement smoothing on': 1,
            'mr displacement edge length': 2,
            'mr displacement max displace': 20,
            'mr displacement parametric subdivision level': 5,
            MaxHandle: 34631,
          }}
        />
        <mesh
          name="Liver_Island_Logo"
          geometry={nodes.Liver_Island_Logo.geometry}
          material={nodes.Liver_Island_Logo.material}
          position={[14.49, 2.2, 6.4]}
          scale={0.17}
          userData={{ name: 'Liver Island Logo' }}
        />
        <mesh
          name="Liver_Island_Logo001"
          geometry={nodes.Liver_Island_Logo001.geometry}
          material={nodes.Liver_Island_Logo001.material}
          position={[-1.19, 4.33, 12.84]}
          scale={0.19}
          userData={{ name: 'Liver Island Logo.001' }}
        />
        <mesh
          name="Liver_Island_Logo002"
          geometry={nodes.Liver_Island_Logo002.geometry}
          material={nodes.Liver_Island_Logo002.material}
          position={[-12.2, 4.35, -3.02]}
          scale={0.19}
          userData={{ name: 'Liver Island Logo.002' }}
        />
      </group>
    </group >
  )
}

useGLTF.preload('/All_Liver_Island.glb')
