/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('House_Visitor.glb')
  if (nodes) {
    setTimeout(() => {
      props.onCreated()
    }, 100)
  }
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        name="Scene"
        userData={{
          carver_target: { name: 'Mueble.003', type: 'Object' },
          glTF2ExportSettings: { export_extras: 1, export_format: 'GLB', use_selection: 1 },
        }}>
        <mesh
          name="House"
          geometry={nodes.House.geometry}
          material={nodes.House.material}
          position={[-1.42, 1.4, 1.15]}
          userData={{ name: 'House' }}
        />
        <mesh
          name="Glass"
          geometry={nodes.Glass.geometry}
          material={nodes.Glass.material}
          position={[-1.95, 0.72, 7.31]}
          userData={{ name: 'Glass' }}
        />
        <mesh
          name="Ground"
          geometry={nodes.Ground.geometry}
          material={nodes.Ground.material}
          position={[0, -0.24, 0.09]}
          userData={{ name: 'Ground' }}
        />
        <group name="Door_1" position={[1.78, 2.33, 4.31]} userData={{ name: 'Door 1' }}>
          <mesh name="Cube002_1" geometry={nodes.Cube002_1.geometry} material={nodes.Cube002_1.material} />
          <mesh name="Cube002_2" geometry={nodes.Cube002_2.geometry} material={nodes.Cube002_2.material} />
        </group>
        <group name="Window" position={[-4.86, 2.46, 4.33]} userData={{ name: 'Window' }}>
          <mesh name="Cube003_1" geometry={nodes.Cube003_1.geometry} material={nodes.Cube003_1.material} />
          <mesh name="Cube003_2" geometry={nodes.Cube003_2.geometry} material={nodes.Cube003_2.material} />
        </group>
        <mesh
          name="Madera_outside"
          geometry={nodes.Madera_outside.geometry}
          material={nodes.Madera_outside.material}
          position={[6.56, -0.07, 5.72]}
          userData={{ name: 'Madera outside' }}
        />
        <mesh
          name="Grass"
          geometry={nodes.Grass.geometry}
          material={nodes.Grass.material}
          position={[-0.54, 0.04, 5.41]}
          userData={{ name: 'Grass' }}
        />
        <group name="House003" position={[-8.22, 1.84, -3.5]} userData={{ name: 'House.003' }}>
          <mesh name="Cube048" geometry={nodes.Cube048.geometry} material={nodes.Cube048.material} />
          <mesh name="Cube048_1" geometry={nodes.Cube048_1.geometry} material={nodes.Cube048_1.material} />
        </group>
        <mesh
          name="Mueble001"
          geometry={nodes.Mueble001.geometry}
          material={nodes.Mueble001.material}
          position={[8.76, 2.19, -1.82]}
          userData={{ name: 'Mueble.001' }}
        />
        <mesh
          name="Mueble002"
          geometry={nodes.Mueble002.geometry}
          material={nodes.Mueble002.material}
          position={[2.41, 1.74, -6.25]}
          userData={{ name: 'Mueble.002' }}
        />
        <mesh
          name="Mueble004"
          geometry={nodes.Mueble004.geometry}
          material={nodes.Mueble004.material}
          position={[4.91, 0.87, -0.7]}
          userData={{ name: 'Mueble.004' }}
        />
        <mesh
          name="Mueble005"
          geometry={nodes.Mueble005.geometry}
          material={nodes.Mueble005.material}
          position={[6.01, 0.87, -0.7]}
          userData={{ name: 'Mueble.005' }}
        />
        <mesh
          name="Mueble006"
          geometry={nodes.Mueble006.geometry}
          material={nodes.Mueble006.material}
          position={[4.91, 0.87, -2.9]}
          userData={{ name: 'Mueble.006' }}
        />
        <mesh
          name="Mueble007"
          geometry={nodes.Mueble007.geometry}
          material={nodes.Mueble007.material}
          position={[6.01, 0.87, -2.9]}
          userData={{ name: 'Mueble.007' }}
        />
        <mesh
          name="Mueble008"
          geometry={nodes.Mueble008.geometry}
          material={nodes.Mueble008.material}
          position={[3.65, 0.87, -1.85]}
          userData={{ name: 'Mueble.008' }}
        />
        <mesh
          name="Mueble009"
          geometry={nodes.Mueble009.geometry}
          material={nodes.Mueble009.material}
          position={[7.26, 0.87, -1.85]}
          userData={{ name: 'Mueble.009' }}
        />
        <mesh
          name="Mueble010"
          geometry={nodes.Mueble010.geometry}
          material={nodes.Mueble010.material}
          position={[5.45, 0.23, -1.75]}
          userData={{ name: 'Mueble.010' }}
        />
        <mesh
          name="Mueble011"
          geometry={nodes.Mueble011.geometry}
          material={nodes.Mueble011.material}
          position={[-3.14, 0.23, 1.1]}
          userData={{ name: 'Mueble.011' }}
        />
        <mesh
          name="Mueble012"
          geometry={nodes.Mueble012.geometry}
          material={nodes.Mueble012.material}
          position={[-1.35, 0.87, -5]}
          userData={{ name: 'Mueble.012' }}
        />
        <mesh
          name="Mueble018"
          geometry={nodes.Mueble018.geometry}
          material={nodes.Mueble018.material}
          position={[-1.64, 0.94, 1.1]}
          userData={{ name: 'Mueble.018' }}
        />
        <mesh
          name="tv"
          geometry={nodes.tv.geometry}
          material={nodes.tv.material}
          position={[-6.77, 1.93, 1.03]}
          userData={{ name: 'tv' }}
        />
        <mesh
          name="Cube002"
          geometry={nodes.Cube002.geometry}
          material={nodes.Cube002.material}
          position={[-2.66, 0.42, -7.96]}
          userData={{ name: 'Cube.002' }}
        />
        <mesh
          name="Cube005"
          geometry={nodes.Cube005.geometry}
          material={nodes.Cube005.material}
          position={[-4.53, 1.05, -6.78]}
          userData={{ name: 'Cube.005' }}
        />
        <mesh
          name="Cube009"
          geometry={nodes.Cube009.geometry}
          material={nodes.Cube009.material}
          position={[-6.53, 0.42, -7.96]}
          userData={{ name: 'Cube.009' }}
        />
        <mesh
          name="Cube001"
          geometry={nodes.Cube001.geometry}
          material={nodes.Cube001.material}
          position={[-0.82, 0.83, -5.59]}
          userData={{ name: 'Cube.001' }}
        />
        <mesh
          name="Cube011"
          geometry={nodes.Cube011.geometry}
          material={nodes.Cube011.material}
          position={[-8.72, 1.55, -5.52]}
          userData={{ name: 'Cube.011' }}
        />
        <mesh
          name="Cube015"
          geometry={nodes.Cube015.geometry}
          material={nodes.Cube015.material}
          position={[-4.9, 1.06, -2.8]}
          userData={{ name: 'Cube.015' }}
        />
        <mesh
          name="Cube022"
          geometry={nodes.Cube022.geometry}
          material={nodes.Cube022.material}
          position={[8.42, 1.89, -5.7]}
          userData={{ name: 'Cube.022' }}
        />
        <mesh
          name="Mesa"
          geometry={nodes.Mesa.geometry}
          material={nodes.Mesa.material}
          position={[5.45, 0.79, -1.84]}
          userData={{ name: 'Mesa' }}
        />
        <group name="Cuadro" position={[-1.18, 2.41, 4.09]} userData={{ name: 'Cuadro' }}>
          <mesh name="Cube039" geometry={nodes.Cube039.geometry} material={nodes.Cube039.material} />
          <mesh name="Cube039_1" geometry={nodes.Cube039_1.geometry} material={materials['Cuadro living room']} />
        </group>
        <mesh
          name="Estanteria_para_tele_"
          geometry={nodes.Estanteria_para_tele_.geometry}
          material={nodes.Estanteria_para_tele_.material}
          position={[-6.77, 0.67, 1.04]}
          userData={{ name: 'Estanteria para tele ' }}
        />
        <mesh
          name="Libro"
          geometry={nodes.Libro.geometry}
          material={nodes.Libro.material}
          position={[-0.66, 1.41, -3.96]}
          userData={{ name: 'Libro' }}
        />
        <mesh
          name="Persianas"
          geometry={nodes.Persianas.geometry}
          material={nodes.Persianas.material}
          position={[-4.86, 2.99, 4.04]}
          userData={{ name: 'Persianas' }}
        />
        <mesh
          name="Persinas_2"
          geometry={nodes.Persinas_2.geometry}
          material={nodes.Persinas_2.material}
          position={[-4.87, 4.32, 4.04]}
          userData={{ name: 'Persinas 2' }}
        />
        <group name="Cuadro001" position={[-4.48, 3.41, -8.26]} userData={{ name: 'Cuadro.001' }}>
          <mesh name="Cube005_1" geometry={nodes.Cube005_1.geometry} material={nodes.Cube005_1.material} />
          <mesh name="Cube005_2" geometry={nodes.Cube005_2.geometry} material={materials['Cuadro 4']} />
        </group>
        <group name="Cuadro002" position={[-6.48, 3.41, -8.26]} userData={{ name: 'Cuadro.002' }}>
          <mesh name="Cube006_1" geometry={nodes.Cube006_1.geometry} material={nodes.Cube006_1.material} />
          <mesh name="Cube006_2" geometry={nodes.Cube006_2.geometry} material={materials['Cuadro 6']} />
        </group>
        <group name="Cuadro003" position={[-2.48, 3.41, -8.26]} userData={{ name: 'Cuadro.003' }}>
          <mesh name="Cube008_1" geometry={nodes.Cube008_1.geometry} material={nodes.Cube008_1.material} />
          <mesh name="Cube008_2" geometry={nodes.Cube008_2.geometry} material={materials['Cuadro 5']} />
        </group>
        <group name="Cuadro004" position={[-7.2, 2.89, 3.35]} userData={{ name: 'Cuadro.004' }}>
          <mesh name="Cube060" geometry={nodes.Cube060.geometry} material={nodes.Cube060.material} />
          <mesh name="Cube060_1" geometry={nodes.Cube060_1.geometry} material={materials['Cuadro 3']} />
        </group>
        <group name="Cuadro005" position={[-7.2, 2.89, -1.33]} userData={{ name: 'Cuadro.005' }}>
          <mesh name="Cube061" geometry={nodes.Cube061.geometry} material={nodes.Cube061.material} />
          <mesh name="Cube061_1" geometry={nodes.Cube061_1.geometry} material={materials['Cuadro 2']} />
        </group>
        <mesh
          name="Libro001"
          geometry={nodes.Libro001.geometry}
          material={nodes.Libro001.material}
          position={[-4.4, 0.52, 0.99]}
          userData={{ name: 'Libro.001' }}
        />
        <mesh
          name="Libro002"
          geometry={nodes.Libro002.geometry}
          material={nodes.Libro002.material}
          position={[-5.46, 1.17, -1.57]}
          userData={{ name: 'Libro.002' }}
        />
        <mesh
          name="Libro003"
          geometry={nodes.Libro003.geometry}
          material={nodes.Libro003.material}
          position={[-5.21, 1.03, -1.57]}
          userData={{ name: 'Libro.003' }}
        />
        <mesh
          name="Libro004"
          geometry={nodes.Libro004.geometry}
          material={nodes.Libro004.material}
          position={[-5.17, 1.08, -1.57]}
          userData={{ name: 'Libro.004' }}
        />
        <mesh
          name="Libro005"
          geometry={nodes.Libro005.geometry}
          material={nodes.Libro005.material}
          position={[-5.22, 1.13, -1.57]}
          userData={{ name: 'Libro.005' }}
        />
        <mesh
          name="Libro006"
          geometry={nodes.Libro006.geometry}
          material={nodes.Libro006.material}
          position={[-4.51, 1.17, -1.57]}
          userData={{ name: 'Libro.006' }}
        />
        <mesh
          name="Libro007"
          geometry={nodes.Libro007.geometry}
          material={nodes.Libro007.material}
          position={[-4.44, 1.17, -1.57]}
          userData={{ name: 'Libro.007' }}
        />
        <mesh
          name="Libro008"
          geometry={nodes.Libro008.geometry}
          material={nodes.Libro008.material}
          position={[-4.35, 1.17, -1.57]}
          userData={{ name: 'Libro.008' }}
        />
        <mesh
          name="Libro009"
          geometry={nodes.Libro009.geometry}
          material={nodes.Libro009.material}
          position={[-3.81, 1.03, -1.57]}
          userData={{ name: 'Libro.009' }}
        />
        <mesh
          name="Libro010"
          geometry={nodes.Libro010.geometry}
          material={nodes.Libro010.material}
          position={[-3.76, 1.08, -1.57]}
          userData={{ name: 'Libro.010' }}
        />
        <mesh
          name="Libro011"
          geometry={nodes.Libro011.geometry}
          material={nodes.Libro011.material}
          position={[-3.81, 1.13, -1.57]}
          userData={{ name: 'Libro.011' }}
        />
        <mesh
          name="Libro012"
          geometry={nodes.Libro012.geometry}
          material={nodes.Libro012.material}
          position={[-3.85, 1.18, -1.57]}
          userData={{ name: 'Libro.012' }}
        />
        <mesh
          name="Libro013"
          geometry={nodes.Libro013.geometry}
          material={nodes.Libro013.material}
          position={[-3.81, 1.23, -1.57]}
          userData={{ name: 'Libro.013' }}
        />
        <mesh
          name="Libro014"
          geometry={nodes.Libro014.geometry}
          material={nodes.Libro014.material}
          position={[-3.38, 1.17, -1.57]}
          userData={{ name: 'Libro.014' }}
        />
        <group name="Cuadro006" position={[-7.2, 3.9, 1.06]} userData={{ name: 'Cuadro.006' }}>
          <mesh name="Cube068" geometry={nodes.Cube068.geometry} material={nodes.Cube068.material} />
          <mesh name="Cube068_1" geometry={nodes.Cube068_1.geometry} material={materials['Cuadro 1']} />
        </group>
        <mesh
          name="Cylinder017"
          geometry={nodes.Cylinder017.geometry}
          material={nodes.Cylinder017.material}
          position={[-4.3, 0.35, 1.2]}
          userData={{ name: 'Cylinder.017' }}
        />
        <mesh
          name="Cylinder012"
          geometry={nodes.Cylinder012.geometry}
          material={nodes.Cylinder012.material}
          position={[-8.9, 2.05, -5.52]}
          userData={{ name: 'Cylinder.012' }}
        />
        <mesh
          name="Cube012"
          geometry={nodes.Cube012.geometry}
          material={nodes.Cube012.material}
          position={[-9.31, 2.92, -5.52]}
          userData={{ name: 'Cube.012' }}
        />
        <mesh
          name="Cube"
          geometry={nodes.Cube.geometry}
          material={nodes.Cube.material}
          position={[-8.36, 0.58, -7.64]}
          userData={{ name: 'Cube' }}
        />
        <mesh
          name="Cylinder013"
          geometry={nodes.Cylinder013.geometry}
          material={nodes.Cylinder013.material}
          position={[-8.46, 3.67, -2.67]}
          userData={{ name: 'Cylinder.013' }}
        />
        <mesh
          name="Cube013"
          geometry={nodes.Cube013.geometry}
          material={nodes.Cube013.material}
          position={[-9.16, 1.7, -3.26]}
          userData={{ name: 'Cube.013' }}
        />
        <mesh
          name="Cube016"
          geometry={nodes.Cube016.geometry}
          material={nodes.Cube016.material}
          position={[-4.42, 1.82, -1.68]}
          userData={{ name: 'Cube.016' }}
        />
        <mesh
          name="Pantalla"
          geometry={nodes.Pantalla.geometry}
          material={nodes.Pantalla.material}
          position={[-0.5, 1.75, -5.04]}
          userData={{ name: 'Pantalla' }}
        />
        <mesh
          name="Teclado"
          geometry={nodes.Teclado.geometry}
          material={nodes.Teclado.material}
          position={[-0.89, 1.39, -5.08]}
          userData={{ name: 'Teclado' }}
        />
        <mesh
          name="Planta"
          geometry={nodes.Planta.geometry}
          material={nodes.Planta.material}
          position={[-3.08, 2.9, -1.58]}
          userData={{ name: 'Planta' }}
        />
        <mesh
          name="Planta001"
          geometry={nodes.Planta001.geometry}
          material={nodes.Planta001.material}
          position={[-5.76, 2.9, -1.58]}
          userData={{ name: 'Planta.001' }}
        />
        <mesh
          name="Planta002"
          geometry={nodes.Planta002.geometry}
          material={nodes.Planta002.material}
          position={[-3.08, 1.68, -1.58]}
          userData={{ name: 'Planta.002' }}
        />
        <mesh
          name="Planta003"
          geometry={nodes.Planta003.geometry}
          material={nodes.Planta003.material}
          position={[-5.76, 1.68, -1.58]}
          userData={{ name: 'Planta.003' }}
        />
        <mesh
          name="Planta004"
          geometry={nodes.Planta004.geometry}
          material={nodes.Planta004.material}
          position={[-3.08, 0.39, -1.58]}
          userData={{ name: 'Planta.004' }}
        />
        <mesh
          name="Planta005"
          geometry={nodes.Planta005.geometry}
          material={nodes.Planta005.material}
          position={[-5.76, 0.39, -1.58]}
          userData={{ name: 'Planta.005' }}
        />
        <mesh
          name="measurement_tape"
          geometry={nodes.measurement_tape.geometry}
          material={materials['Measurement tape']}
          position={[4.52, 1.3, -1.46]}
          userData={{ name: 'measurement tape' }}
        />
        <mesh
          name="Cube014"
          geometry={nodes.Cube014.geometry}
          material={nodes.Cube014.material}
          position={[-0.9, 1.39, -4.57]}
          userData={{ name: 'Cube.014' }}
        />
        <mesh
          name="Cylinder020"
          geometry={nodes.Cylinder020.geometry}
          material={nodes.Cylinder020.material}
          position={[-4.87, 2.81, -2.54]}
          userData={{ name: 'Cylinder.020' }}
        />
        <mesh
          name="Cube018"
          geometry={nodes.Cube018.geometry}
          material={nodes.Cube018.material}
          position={[-4.11, 0.51, 1.27]}
          userData={{ name: 'Cube.018' }}
        />
        <mesh
          name="Planta006"
          geometry={nodes.Planta006.geometry}
          material={nodes.Planta006.material}
          position={[-4.56, 0.57, 1.51]}
          userData={{ name: 'Planta.006' }}
        />
        <mesh
          name="Cylinder022"
          geometry={nodes.Cylinder022.geometry}
          material={nodes.Cylinder022.material}
          position={[2.93, 1.41, 3.02]}
          userData={{ name: 'Cylinder.022' }}
        />
        <mesh
          name="Cylinder023"
          geometry={nodes.Cylinder023.geometry}
          material={nodes.Cylinder023.material}
          position={[0.93, 1.41, -2.71]}
          userData={{ name: 'Cylinder.023' }}
        />
        <mesh
          name="Cylinder024"
          geometry={nodes.Cylinder024.geometry}
          material={nodes.Cylinder024.material}
          position={[-3.72, 1.81, -1.62]}
          userData={{ name: 'Cylinder.024' }}
        />
        <mesh
          name="Cylinder025"
          geometry={nodes.Cylinder025.geometry}
          material={nodes.Cylinder025.material}
          position={[-5.06, 1.81, -1.62]}
          userData={{ name: 'Cylinder.025' }}
        />
        <mesh
          name="Cylinder026"
          geometry={nodes.Cylinder026.geometry}
          material={nodes.Cylinder026.material}
          position={[-3.72, 3.07, -1.62]}
          userData={{ name: 'Cylinder.026' }}
        />
        <mesh
          name="Cylinder027"
          geometry={nodes.Cylinder027.geometry}
          material={nodes.Cylinder027.material}
          position={[-5.06, 3.07, -1.62]}
          userData={{ name: 'Cylinder.027' }}
        />
        <mesh
          name="Cylinder014"
          geometry={nodes.Cylinder014.geometry}
          material={nodes.Cylinder014.material}
          position={[-6.5, 1.28, 7.11]}
          userData={{ name: 'Cylinder.014' }}
        />
        <mesh
          name="Cylinder015"
          geometry={nodes.Cylinder015.geometry}
          material={nodes.Cylinder015.material}
          position={[-6.42, 0.88, 3.46]}
          userData={{ name: 'Cylinder.015' }}
        />
        <mesh
          name="Cylinder018"
          geometry={nodes.Cylinder018.geometry}
          material={nodes.Cylinder018.material}
          position={[-6.57, 0.88, -1.51]}
          userData={{ name: 'Cylinder.018' }}
        />
        <mesh
          name="Cylinder019"
          geometry={nodes.Cylinder019.geometry}
          material={nodes.Cylinder019.material}
          position={[-0.6, 0.88, -7.73]}
          userData={{ name: 'Cylinder.019' }}
        />
        <mesh
          name="Cylinder028"
          geometry={nodes.Cylinder028.geometry}
          material={nodes.Cylinder028.material}
          position={[4, 0.88, 1.48]}
          userData={{ name: 'Cylinder.028' }}
        />
        <mesh
          name="Cube003"
          geometry={nodes.Cube003.geometry}
          material={nodes.Cube003.material}
          position={[-1.78, 0.7, 7.01]}
          userData={{ name: 'Cube.003' }}
        />
        <mesh
          name="Cube019"
          geometry={nodes.Cube019.geometry}
          material={nodes.Cube019.material}
          position={[-3.03, 0.74, 7.03]}
          userData={{ name: 'Cube.019' }}
        />
        <mesh
          name="Cube004"
          geometry={nodes.Cube004.geometry}
          material={nodes.Cube004.material}
          position={[-0.45, 0.74, 6.97]}
          userData={{ name: 'Cube.004' }}
        />
        <mesh
          name="Cylinder001"
          geometry={nodes.Cylinder001.geometry}
          material={nodes.Cylinder001.material}
          position={[2.82, 1.28, 7.32]}
          userData={{ name: 'Cylinder.001' }}
        />
        <mesh
          name="Cube006"
          geometry={nodes.Cube006.geometry}
          material={nodes.Cube006.material}
          position={[-1.76, 0.74, 5.86]}
          userData={{ name: 'Cube.006' }}
        />
        <mesh
          name="Cube007"
          geometry={nodes.Cube007.geometry}
          material={nodes.Cube007.material}
          position={[-1.25, 3.56, 4.69]}
          userData={{ name: 'Cube.007' }}
        />
        <mesh
          name="Cube008"
          geometry={nodes.Cube008.geometry}
          material={nodes.Cube008.material}
          position={[-1.25, 3.04, 4.69]}
          userData={{ name: 'Cube.008' }}
        />
        <mesh
          name="Planta007"
          geometry={nodes.Planta007.geometry}
          material={nodes.Planta007.material}
          position={[-1.25, 3.21, 4.78]}
          userData={{ name: 'Planta.007' }}
        />
        <mesh
          name="Planta008"
          geometry={nodes.Planta008.geometry}
          material={nodes.Planta008.material}
          position={[-1.25, 3.72, 4.78]}
          userData={{ name: 'Planta.008' }}
        />
        <mesh
          name="Apple005"
          geometry={nodes.Apple005.geometry}
          material={nodes.Apple005.material}
          position={[-1.78, 1.71, 7.07]}
          userData={{ name: 'Apple.005', currentUVSet: 'map1' }}
        />
        <mesh
          name="Cylinder"
          geometry={nodes.Cylinder.geometry}
          material={nodes.Cylinder.material}
          position={[-1.79, 1.8, 6.58]}
          userData={{ name: 'Cylinder' }}
        />
        <mesh
          name="Cylinder002"
          geometry={nodes.Cylinder002.geometry}
          material={nodes.Cylinder002.material}
          position={[-1.33, 1.61, 7]}
          userData={{ name: 'Cylinder.002' }}
        />
        <mesh
          name="Cylinder003"
          geometry={nodes.Cylinder003.geometry}
          material={nodes.Cylinder003.material}
          position={[-2.26, 1.61, 7.27]}
          userData={{ name: 'Cylinder.003' }}
        />
        <mesh
          name="Cylinder004"
          geometry={nodes.Cylinder004.geometry}
          material={nodes.Cylinder004.material}
          position={[-1.48, 1.61, 6.47]}
          userData={{ name: 'Cylinder.004' }}
        />
        <mesh
          name="Apple001"
          geometry={nodes.Apple001.geometry}
          material={nodes.Apple001.material}
          position={[6.14, 1.5, -1.81]}
          userData={{ name: 'Apple.001', currentUVSet: 'map1' }}
        />
        <mesh
          name="Apple002"
          geometry={nodes.Apple002.geometry}
          material={nodes.Apple002.material}
          position={[5.01, 1.5, -1.81]}
          userData={{ name: 'Apple.002', currentUVSet: 'map1' }}
        />
        <mesh
          name="Plane"
          geometry={nodes.Plane.geometry}
          material={nodes.Plane.material}
          position={[5.6, 1.29, -1.42]}
          userData={{ name: 'Plane' }}
        />
        <mesh
          name="Cube017"
          geometry={nodes.Cube017.geometry}
          material={nodes.Cube017.material}
          position={[9.2, 0.54, 0.67]}
          userData={{ name: 'Cube.017' }}
        />
        <mesh
          name="Cube010"
          geometry={nodes.Cube010.geometry}
          material={nodes.Cube010.material}
          position={[9.28, 0.91, 1.37]}
          userData={{ name: 'Cube.010' }}
        />
        <mesh
          name="Plane001"
          geometry={nodes.Plane001.geometry}
          material={nodes.Plane001.material}
          position={[8.85, 0.78, 1.78]}
          userData={{ name: 'Plane.001' }}
        />
        <mesh
          name="Cube020"
          geometry={nodes.Cube020.geometry}
          material={nodes.Cube020.material}
          position={[9.3, 0.9, 1.83]}
          userData={{ name: 'Cube.020' }}
        />
        <mesh
          name="Liver_Island_Logo"
          geometry={nodes.Liver_Island_Logo.geometry}
          material={materials['Logo Liver Island']}
          position={[-4.57, 4.02, -1.99]}
          scale={0.49}
          userData={{ name: 'Liver Island Logo' }}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/House_Visitor.glb')
