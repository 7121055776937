// import $ from 'jquery'
// import { AppContext } from "../context";

const animPos = require('../static/campos.json');

class ClickActions {
  constructor(context) {
    this.el = null;
    this.context = context;
  }

  checkClick(e) {
    e.stopPropagation()
    // console.log("Clicked", e.object.name)
    // check if the click happend without dragging the mouse
    if (e.delta <= 1) {
      console.log("Clicked",
        e.object.name,
        e.object.position,
        e
        // {
        //   width: e.object.geometry.parameters.width,
        //   height: e.object.geometry.parameters.height,
        //   depth: e.object.geometry.parameters.depth
        // }
      )
    }
  }

}
export default ClickActions;