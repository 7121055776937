class Functions {
  constructor() {
    this.functions = {};
    this.init();
  }

  init() {
    // this.mouseTracker()
  }

  mouseTracker() {

    const mousemove = (event) => {
      // console.log("pageX: ", event.pageX,
      //   "pageY: ", event.pageY,
      //   "clientX: ", event.clientX,
      //   "clientY:", event.clientY)

      /*
        width screen = 1920
        center screen = 1920 / 2 = 960
      */
      var mouseX = ((event.clientX - (window.innerWidth / 2)) / (window.innerWidth / 2) * 100).toFixed(2)
      window.mousePos.x = mouseX;
      window.cameraControls.setLookAt(mouseX, 0, 0, 0, 0, 0, false)
      // this.consoleLog(JSON.stringify({
      //   screenWidth: window.innerWidth,
      //   mouseXpos: event.pageX,
      //   mouseX: parseFloat(mouseX),
      // }, null, 2));
    }

    window.addEventListener('mousemove', mousemove);
  }

  showLoader() {
    console.log("Show Loader")
    document.querySelector('#sceneSwitcher').style.display = 'block';
  }

  hideLoader() {
    console.log("Hide Loader")
    document.querySelector('#sceneSwitcher').style.display = 'none';
  }

  updateLoading(active, progress, errors, item, loaded, total) {
    console.log('progress', progress)
    if (progress === 100) {
      //
    }
  }

  consoleLog(_val) {
    var consoleDiv = document.getElementById('debugConsole')
    if (consoleDiv) {
      consoleDiv.innerHTML = _val;
    }
  }

}
export default Functions;