import * as THREE from 'three'
import React, { Suspense, useMemo, useState, useRef, forwardRef } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Plane, Cone, Box, Sky } from "@react-three/drei"
import { Controls as DatGuiControls, useControl, withControls } from 'react-three-gui';
import CameraControls from 'camera-controls'

import ClickActions from './ClickActions'
import { AppContext } from "../context";
import DatGui from './DatGui'


/** Load Objects */
import LiverIsland from './gltfjsx/All_Liver_Island.js'
import Huisje from './gltfjsx/House_Visitor.js'
import Bioscoop from './gltfjsx/Cinema.js'
import Ziekenhuis from './gltfjsx/Care_center.js'


const animPos = require('../static/campos.json');
const clickBoxJson = require('../static/clickbox.json');

CameraControls.install({ THREE: THREE });

const SceneCanvas = (props) => {
  // fix mouse pointer on hover click items 
  // const [hovered, setHovered] = useState(false)
  // React.useEffect(() => {
  //   document.body.style.cursor = hovered ? 'pointer' : 'auto'
  // }, [hovered])

  console.log('SceneCanvas props => ', props)
  // const canvas = React.useRef()
  const [ready, setReady] = React.useState(false);
  const [zoomLevel, setZoomLevel] = React.useState(0);
  const [logExtra, setLogExtra] = React.useState({});
  const context = React.useContext(AppContext);

  // const [camAnimData, setCamAnimData] = React.useState(animPos.mainPos);
  window.camAnimData = animPos.mainPos;

  /** DAT GUI CONTROLS */
  const [selectedScene, setSelectedScene] = React.useState('eiland');
  window.setSelectedScene = setSelectedScene;
  React.useEffect(() => {
    // function here
    window.selectedScene = selectedScene;
  }, [selectedScene])

  // camera position 
  const [camPos, setCamPos] = React.useState({ x: -60, y: 15, z: 40 });
  const updateCamPos = (key, val) => {
    setCamPos(prevState => {
      return { ...prevState, [key]: val };
    });
  }
  // look at indicator
  const [lookPos, setLookPos] = React.useState({ x: 0, y: 1, z: 0 });
  const updatLookPos = (key, val) => {
    setLookPos(prevState => {
      return { ...prevState, [key]: val };
    });
  }
  // light intensity day / night
  const [ambientLightIntensity, setAmbientLightIntensity] = React.useState(1);
  window.sceneData.ambientLightIntensity = ambientLightIntensity;
  window.sceneData.setAmbientLightIntensity = setAmbientLightIntensity;
  // useControl('Lighting', { group: 'Lights', type: 'number', min: 0, max: 1, state: [ambientLightIntensity, setAmbientLightIntensity] });

  // LOOK BOX INDICATOR 
  const cone = useRef()
  window.lookBox = cone;
  const LookBox = () => {
    console.log("Render LookBox")
    if (!props.datGui ?? true) {
      return null;
    }
    return <Cone ref={cone} scale={0.5} position={[animPos.mainPos.lookAt.x, animPos.mainPos.lookAt.y, animPos.mainPos.lookAt.z]} rotation={[Math.PI, 0, 0]}>
      <meshBasicMaterial attach="material" color="red" wireframe />
    </Cone>
  }

  const Controls = () => {
    const camera = useThree((state) => state.camera)
    const gl = useThree((state) => state.gl)
    let cameraDirection = new THREE.Vector3()
    const controls = useMemo(() => {
      return new CameraControls(camera, gl.domElement);
    }, []);
    window.cameraControls = controls;
    controls.dampingFactor = 0;
    controls.setLookAt(
      animPos.mainPos.camPosition.x,
      animPos.mainPos.camPosition.y,
      animPos.mainPos.camPosition.z,
      animPos.mainPos.lookAt.x,
      animPos.mainPos.lookAt.y,
      animPos.mainPos.lookAt.z,
      true
    )
    let lastCameraData = {};
    let didLog = false;
    let isCalculating = false;
    useFrame((state, delta) => {

      // update controls
      camera.getWorldDirection(cameraDirection)
      cameraDirection.set(cameraDirection.x * 100, cameraDirection.y * 100, cameraDirection.z * 100)

      window.currentCameraData = {
        lookAt: {
          x: camera.position.x + cameraDirection.x,
          y: camera.position.y + cameraDirection.y,
          z: camera.position.z + cameraDirection.z
        },
        camPosition: camera.position
      }
      if (JSON.stringify(lastCameraData) !== JSON.stringify(window.currentCameraData)) {
        isCalculating = true;
        lastCameraData = window.currentCameraData;
        // if (cone.current) {
        //   cone.current.position.x = camera.position.x + cameraDirection.x
        //   cone.current.position.z = camera.position.z + cameraDirection.z
        // }
        window.functions.consoleLog(JSON.stringify({
          lookAt: {
            x: cone?.current?.position.x ?? camera.position.x + cameraDirection.x,
            y: cone?.current?.position.y ?? camera.position.y + cameraDirection.y,
            z: cone?.current?.position.z ?? camera.position.z + cameraDirection.z
          },
          camPosition: camera.position
        }, null, 2))
      } else {
        if (isCalculating) {
          isCalculating = false;
          controls.setLookAt(
            camera.position.x,
            camera.position.y,
            camera.position.z,
            cone?.current?.position.x ?? camera.position.x + cameraDirection.x,
            cone?.current?.position.y ?? camera.position.y + cameraDirection.y,
            cone?.current?.position.z ?? camera.position.z + cameraDirection.z,
            false
          )
        }
      }

      controls.update(delta)
      if ((props.datGui ?? false) && cone.current) {
        if (!didLog) {
          didLog = true;
          console.log("Cone", cone.current)
        }
        cone.current.rotation.y += 0.03
      }
    })
    return null;
  }

  const light1 = useRef()
  const light2 = useRef()
  const light3 = useRef() // moonlight
  const sky = useRef()
  window.sceneData.lighting = { light1, light2, light3, sky };
  const Lighting = () => {
    const light = 1;
    return (
      <>
        <ambientLight ref={light1} intensity={light * 1.25} />
        <pointLight ref={light2} intensity={light} position={[0, 1000, 0]} />
        <pointLight ref={light3} intensity={0.3 - light} position={[0, 100, 100]} />
        <Sky
          ref={sky}
          azimuth={0.1}
        />
      </>
    )
  }

  const clickActions = new ClickActions(context);
  const onClicker = (e) => {
    clickActions.checkClick(e);
  }

  const YourCanvas = withControls(Canvas);

  const ShowItem = () => React.useMemo(() => {
    switch (selectedScene) {
      case 'eiland':
        return <LiverIsland onClick={onClicker} />
        break;
      case 'huisje':
        return <Huisje onClick={onClicker} />;
        break;
      case 'ziekenhuis':
        return <Ziekenhuis onClick={onClicker} />;
        break;
      case 'bios':
        return <Bioscoop onClick={onClicker} />;
        break;
      default:
        return <LiverIsland onClick={onClicker} />
        break;
    }
  }, [selectedScene])

  const clickbox = useRef()
  window.clickbox = clickbox;
  window.clickboxData = {
    position: [0, 0, 0],
    scale: [10, 10, 10]
  }
  const clickBoxes = () => {
    return null;
    var objects = []; let mouseOverCounter = 0;
    for (let name of Object.keys(clickBoxJson)) {
      objects.push(
        <Box
          key={name}
          name={name}
          args={clickBoxJson[name].dimensions}
          position={clickBoxJson[name].position}
          onClick={onClicker}
          onPointerOver={() => {
            mouseOverCounter++;
            document.body.style.cursor = 'pointer'
          }}
          onPointerOut={() => {
            mouseOverCounter--;
            if (mouseOverCounter === 0) {
              document.body.style.cursor = 'auto'
            }
          }}
        >
          <meshBasicMaterial attach="material" color="red" opacity={0.5} transparent />
        </Box>
      )
    }
    // objects.push(
    //   <Box
    //     ref={clickbox}
    //     key={'positioning_item'}
    //     name={'positioning_item'}
    //     args={[10, 2.3, 12]}
    //     position={[-15.2, 0.7, 19.9]}
    //     onClick={onClicker}
    //     onPointerOver={() => {
    //       document.body.style.cursor = 'pointer'
    //     }}
    //     onPointerOut={() => {
    //       document.body.style.cursor = 'auto'
    //     }}
    //   >
    //     <meshBasicMaterial attach="material" color="red" opacity={0.5} transparent />
    //   </Box>
    // )
    // return objects;
  }


  var breedte = 5;
  var hoogte = 5;
  var lengte = 6.3;
  return (
    <>
      <YourCanvas
        // ref={canvas}
        gl={false}
        mode="concurrent"
        frameloop="always"
        raycaster={true}
        damping={false}
        shadows={false}
        flat={true}
        linear={false}
        orthographic={false}
        updateDefaultCamera={false}
        style={{ position: 'absolute', backgroundColor: '#171717', opacity: 1 }}
        camera={{
          fov: 45,
          near: 1,
          far: 100,
          focus: 0.1,
          zoom: 1,
          blur: 0.5,
          position: [animPos.mainPos.camPosition.x, animPos.mainPos.camPosition.y, animPos.mainPos.camPosition.z],
          // lookAt: [animPos.mainPos.lookAt.x, animPos.mainPos.lookAt.y, animPos.mainPos.lookAt.z],
          rotation: [0.93, 0.51, -0.58],
          userData: {
            hasDefaults: false
          }
        }}
        onCreated={(_this) => {
          props.onCreated(_this);
          setReady(true);
        }}
      >
        {ready ? (
          <>
            <Suspense fallback={null}>
              {/* <LiverIsland /> */}
              <ShowItem />
              <LookBox />
              {clickBoxes()}
              {/* <ClickBox
                name="action_huisjeA"
                args={clickBoxJson.action_huisjeA.dimensions}
                position={clickBoxJson.action_huisjeA.position}
                onClick={onClicker}
              />
              <ClickBox
                name="action_ziekenhuisA"
                args={clickBoxJson.action_ziekenhuisA.dimensions}
                position={clickBoxJson.action_ziekenhuisA.position}
                onClick={onClicker}
              />
              <ClickBox
                name="action_parkA"
                args={clickBoxJson.action_parkA.dimensions}
                position={clickBoxJson.action_parkA.position}
                onClick={onClicker}
              />
              <ClickBox
                name="action_bioscoopA"
                args={[breedte, hoogte, lengte]}
                position={[-27.4, hoogte / 2, -4.8]}
                onClick={onClicker}
              /> */}
              <Lighting />
            </Suspense>
            <Controls />
          </>
        ) : null}
      </YourCanvas>
      {props.datGui && <DatGui />}
    </>
  );
}
export default SceneCanvas;