import $ from 'jquery'
import React from 'react';
import { Controls } from 'react-three-gui';

import './App.css';
import SceneCanvas from './components/SceneCanvas'
import HtmlOverlay from './components/HtmlOverlay';
import LoadingOverlay from './components/LoadingOverlay';
import Functions from './functions'

import { AppContext } from "./context";

global.serverURL = "https://leverstijl.local";

const functions = new Functions();
window.functions = functions;

window.mousePos = { x: 0, y: 0 };
window.sceneData = {}


function App() {
  // hooks 
  const [enableDatGui] = React.useState(true);
  const [scenario, setScenario] = React.useState('eiland');

  // jquery buttons click handler 
  $('#root').on('click', 'button', (e) => {
    e.stopPropagation()
    console.log('clicked', e)
    var overlay = document.querySelector('#overlayContainer');
    var _this = $(e.currentTarget);
    var dataAction = _this.attr('data-action');
    if (dataAction) {
      // get this action 
      var split = dataAction.split('-');
      if (split[0] === 'goto') {
        console.log('goto', split[1])
        overlay.classList.add('fadeOut');
        setScenario(split[1])
        document.querySelector('#overlayContainer').classList.remove('fadeOut');
        document.querySelector('#overlayContainer').classList.add('fadeIn');
        document.querySelector('#overlayContainer').style.display = 'none';
      }
    }
  });

  const onCanvasCreated = (canvas) => {
    console.log('onCanvasCreated', canvas)
    // inject datgui 
    if (enableDatGui) {
      const datGuiContainer = document.querySelector('div[data-anchor=top_right]');
      // console.log("datGuiContainer", datGuiContainer)
      // beforeend
      if (datGuiContainer !== null && document.getElementById('injected-datguilog') === null) {
        datGuiContainer?.lastChild?.insertAdjacentHTML('beforeend', `<div class="px-3" id="injected-datguilog">
          <h2 style="font-family: sans-serif; font-size: 13px; font-weight: bold;">Camera Orientation</h2>
          <pre id="debugConsole"></pre>
        </div>`);
      }
    }
  }

  if (enableDatGui) {
    return (
      <Controls.Provider>
        <SceneCanvas scenario={scenario} onCreated={onCanvasCreated} datGui={enableDatGui} />
      </Controls.Provider>
    )
  }
  return <SceneCanvas scenario={scenario} onCreated={onCanvasCreated} datGui={enableDatGui} />
  // return (
  //   <>
  //     {/* <LoadingOverlay datGui={enableDatGui} /> */}
  //     {/* <HtmlOverlay /> */}
  //     {enableDatGui ? (
  //       <Controls.Provider>
  //         <SceneCanvas scenario={scenario} onCreated={onCanvasCreated} datGui={enableDatGui} />
  //       </Controls.Provider>
  //     ) : (
  //       <SceneCanvas scenario={scenario} onCreated={onCanvasCreated} datGui={enableDatGui} />
  //     )}
  //   </>
  // );
}

export default App;
/* 
<AppContext.Provider value={appContext}>

</AppContext.Provider>
*/