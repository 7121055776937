import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

window.showLoading = true;
window.defaultCamPos = [-60, 15, 40]
window.defaultCamRot = [0.93, 0.51, -0.58]


ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// React.StrictMode
// React.Fragment