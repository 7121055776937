import * as THREE from 'three';
import React from "react";
import { Controls, useControl, withControls } from 'react-three-gui';

const areEqual = (prevProps, nextProps) => true;
const DatGui = React.memo(props => {
  console.log("generate datgui")
  const vector = new THREE.Vector3();
  // window.cameraControls

  const animateCamera = (_type, _key, _val) => {
    // update campos
    window.camAnimData[_type][_key] = _val; //{ ...window.camAnimData, { [_type][_key]: _val }
    // update conelookbox postition
    window.lookBox.current.position.x = window.camAnimData.lookAt.x
    window.lookBox.current.position.y = window.camAnimData.lookAt.y
    window.lookBox.current.position.z = window.camAnimData.lookAt.z
    // update camera position
    window.cameraControls.setLookAt(
      window.currentCameraData.camPosition.x,
      window.currentCameraData.camPosition.y,
      window.currentCameraData.camPosition.z,
      window.camAnimData.lookAt.x,
      window.camAnimData.lookAt.y,
      window.camAnimData.lookAt.z,
      false
    )
    window.functions.consoleLog(JSON.stringify({
      lookAt: window.camAnimData.lookAt,
      camPosition: window.camAnimData.camPosition
    }, null, 2))
  }


  const calcPosFromAngles = (inclination, azimuth) => {
    const theta = Math.PI * (inclination - 0.5)
    const phi = 2 * Math.PI * (azimuth - 0.5)
    // var vec = new THREE.Vector3(Math.cos(phi), Math.sin(theta), Math.sin(phi))
    return { x: Math.cos(phi), y: Math.sin(theta), z: Math.cos(phi) }
  }


  const updateLighting = (_val) => {
    // console.log(window.sceneData.lighting.sky.current)
    window.sceneData.lighting.light1.current.intensity = _val * 1.25;
    window.sceneData.lighting.light2.current.intensity = _val;
    window.sceneData.lighting.light3.current.intensity = (0.3 - _val);

    // vector.set(0, (_val * 1) + 0.25, 0);
    var sunPos = calcPosFromAngles(_val + 0.25, 0.1);
    // console.log("new sun pos => " + JSON.stringify(sunPos))
    window.sceneData.lighting.sky.current.material.uniforms.sunPosition.value.x = sunPos.x;
    window.sceneData.lighting.sky.current.material.uniforms.sunPosition.value.y = sunPos.y;
    window.sceneData.lighting.sky.current.material.uniforms.sunPosition.value.z = sunPos.z;

    window.sceneData.lighting.sky.current.material.uniforms.rayleigh.value = (_val * 1) + 0.25;
    window.sceneData.lighting.sky.current.material.uniforms.turbidity.value = (_val * 120);

    // window.sceneData.lighting.sky.current.inclination = (_val * 1) + 0.25;
    // window.sceneData.lighting.sky.current.rayleigh = (_val * 1) + 0.25;
    // window.sceneData.lighting.sky.current.turbidity = (_val * 120);
  }

  // scene selector 
  useControl('Scene', {
    type: 'select',
    value: 'eiland',
    items: ['eiland', 'huisje', 'bios', 'ziekenhuis'],
    state: [window.selectedScene ?? 'eiland', (val) => window.setSelectedScene(val)]
  })

  // camera position
  // const [camX, setCamX] = React.useState(window.camAnimData.camPosition.x);
  // const updateCamX = (_val) => {
  //   setCamX(_val);
  //   animateCamera('camPosition', 'x', _val)
  // }
  // useControl('cam-X', {
  //   group: 'Camera',
  //   type: 'number',
  //   spring: false,
  //   min: -50,
  //   max: 50,
  //   value: camX,
  //   state: [camX, updateCamX]
  // });
  // useControl('cam-Y', {
  //   group: 'Camera',
  //   type: 'number',
  //   spring: false,
  //   min: 1,
  //   max: 75,
  //   value: window.camAnimData.camPosition.y,
  //   state: [window.camAnimData.camPosition.y, (val) => animateCamera('camPosition', 'y', val)]
  // });
  // useControl('cam-Z', {
  //   group: 'Camera',
  //   type: 'number',
  //   spring: false,
  //   min: -50,
  //   max: 50,
  //   value: window.camAnimData.camPosition.z,
  //   state: [window.camAnimData.camPosition.z, (val) => animateCamera('camPosition', 'z', val)]
  // });

  // look at indicator
  useControl('look-X', {
    group: 'Look Target',
    type: 'number',
    spring: false,
    min: -30,
    max: 30,
    state: [window.camAnimData.lookAt.x, (val) => animateCamera('lookAt', 'x', val)]
  });
  useControl('look-Y', {
    group: 'Look Target',
    type: 'number',
    spring: false,
    min: 0,
    max: 10,
    state: [window.camAnimData.lookAt.y, (val) => animateCamera('lookAt', 'y', val)]
  });
  useControl('look-Z', {
    group: 'Look Target',
    type: 'number',
    spring: false,
    min: -25,
    max: 25,
    state: [window.camAnimData.lookAt.z, (val) => animateCamera('lookAt', 'z', val)]
  });

  // light intensity day / night
  useControl('Lighting', {
    group: 'Lights',
    type: 'number',
    min: 0,
    max: 1,
    state: [window.sceneData.ambientLightIntensity, (val) => updateLighting(val)]
  });


  // clickbox settings 
  const updateClickbox = (_type, _key, _val) => {
    if (_type !== 'geometry') {
      window.clickbox.current[_type][_key] = _val
    } else {
      window.clickbox.current[_type]['parameters'][_key] = _val
    }
    console.log(window.clickbox.current.position)
  }
  useControl('BOX-X', {
    group: 'Clickbox',
    type: 'number',
    min: -30,
    max: 30,
    state: [window.clickboxData.position[0], (val) => updateClickbox('position', 'x', val)]
  });
  useControl('BOX-Y', {
    group: 'Clickbox',
    type: 'number',
    min: -30,
    max: 30,
    state: [window.clickboxData.position[0], (val) => updateClickbox('position', 'y', val)]
  });
  useControl('BOX-Z', {
    group: 'Clickbox',
    type: 'number',
    min: -30,
    max: 30,
    state: [window.clickboxData.position[0], (val) => updateClickbox('position', 'z', val)]
  });
  useControl('BOX-W', {
    group: 'Clickbox',
    type: 'number',
    min: -30,
    max: 30,
    state: [window.clickboxData.position[0], (val) => updateClickbox('geometry', 'width', val)]
  });

  return (
    <Controls
      title="Leverstijl Controls"
      collapsed={true}
      defaultClosedGroups={[
        'Clickbox',
        'Lights'
      ]}
    />
  )
}, areEqual)
export default DatGui;